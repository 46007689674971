import React from "react";
import {Link} from "react-router-dom";
import ProjectPageContent from "./ProjectPageContent";
import '../styles/ProjectPage.css'
import SubPageHeader from "./SubPageHeader";

const ProjectPage = ({match}) => {
    return (
        <>
            <div className='projectPage'>
                <div className='projectPage__header'>
                    <SubPageHeader center={'portfolio'}
                                   bottom={'przejrzyj moje projekty'}/>
                </div>
                <div className='projectPage__content'>
                    <ProjectPageContent id={match.params.link}/>
                </div>
                <Link className='offerContent__button projectPage__button' to='/portfolio'
                      onClick={() => window.scrollTo(0, 0)}>powrót</Link>
            </div>
        </>
    )
};

export default ProjectPage