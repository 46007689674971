import React from "react";
import "../styles/AboutUsWhyUs.css"
import i4 from "../images/icon/i4.svg"
import i5 from "../images/icon/i5.svg"
import i6 from "../images/icon/i6.svg"
import i7 from "../images/icon/i7.svg"

class ProjectPageBox extends React.Component {
    desc = [
        {
            icon: i4,
            header: 'POZNANIE POTRZEB',
            text: 'Pragnę dowiedzieć się jak najwięcej o Twoich ulubionych stylach, kolorach, zapachach, prowadzonym przez Ciebie trybie życia, potrzebach, planach i marzeniach. Po omówieniu szczegółów przychodzi czas na wymiarowanie oraz dokonanie dokumentacji.',
        },
        {
            icon: i5,
            header: 'PIERWSZE PODEJŚCIE PROJEKTOWE',
            text: 'Na podstawie zebranych wytycznych przystępuję do prac projektowych. Owocem będzie wstępna koncepcja, którą w tym momencie możemy wspólnie modyfikować oraz wprowadzić ewentualne zmiany.',
        },
        {
            icon: i6,
            header: 'ZATWIERDZENIE KONCEPCJI',
            text: 'Po wprowadzeniu ewentualnych zmian w koncepcji projektowej, przed przystąpieniem do wykonywania części technicznej czekam na jej zatwierdzenie przez Ciebie.',
        },
        {
            icon: i7,
            header: 'CZĘŚĆ TECHNICZNA – FINALNA',
            text: 'Przystępuję do wykonania dokumentacji, która zawiera niezbędne rysunki techniczne. Gotowy projekt oddaję w Twoje ręce w formie książki bądź w wersji elektronicznej zawierającej wszelkie rysunki i wizualizacje.',
        },
    ];
    contents = this.desc.map(content => (
        <div key={content.icon} className='aboutUsPage__whyUs-box'
             ref={boxRef => this.boxRef.push(boxRef)}>
            <img className='aboutUsPage__whyUs-box-img' src={content.icon} alt={content.header}/>
            <h1 className='aboutUsPage__whyUs-box-header'>{content.header}</h1>
            <p className='aboutUsPage__whyUs-box-text aboutUsPage__whyUs-box-text--project'>{content.text}</p>
        </div>
    ));

    constructor(props, context) {
        super(props, context);
        this.boxRef = [];
        this.descRef = React.createRef()
    }

    handleShow = () => {
        const boxRef = this.boxRef;
        boxRef.forEach(ref => {
            if (ref.getBoundingClientRect().top - 200 < window.innerHeight) {
                ref.classList.add('aboutUsPage__whyUs-box-show')
            } else {
                ref.classList.remove('aboutUsPage__whyUs-box-show')
            }
        });
    };

    componentDidMount() {
        this.handleShow();
        window.addEventListener('scroll', this.handleShow)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleShow)
    }

    render() {
        return (
            <>
                <div className='aboutUsPage__whyUs'>
                    {this.contents}
                </div>
            </>
        )
    }
}

export default ProjectPageBox
