import React from "react";
import {Link} from 'react-router-dom';
import '../styles/OfferBlock.css'

import GA from '../images/icon/GA.svg'
import GB from '../images/icon/GB.svg'
import GC from '../images/icon/GC.svg'
import GD from '../images/icon/GD.svg'
import GE from '../images/icon/GE.svg'
import GF from '../images/icon/GG.svg'
import GG from '../images/icon/GF.svg'
import GH from '../images/icon/GH.svg'
import GI from '../images/icon/GI.svg'
import GJ from '../images/icon/GJ.svg'
import GK from '../images/icon/GK.svg'
import GL from '../images/icon/GL.svg'

import IA from '../images/icon/IA.svg'
import IB from '../images/icon/IB.svg'
import IC from '../images/icon/IC.svg'
import ID from '../images/icon/ID.svg'
import IE from '../images/icon/IE.svg'
import IF from '../images/icon/IF.svg'
import IG from '../images/icon/IG.svg'
import IH from '../images/icon/IH.svg'
import II from '../images/icon/II.svg'

const gardens = [
    {link: "projekty", name: "Projekty", icon: GA},
    {link: "elewacja", name: "Elewacja", icon: GB},
    {link: "oswietlenie", name: "Oświetlenie", icon: GC},
    {link: "ogrodzenia", name: "Ogrodzenia", icon:GD},
    {link: "nawierzchnia", name: "Nawierzchnia", icon:GE},
    {link: "tarasy-i-balkony", name: "Tarasy i balkony", icon:GF},
    {link: "mala-architektura", name: "Mała architektura", icon:GG},
    {link: "zielone-sciany", name: "Zielone ściany", icon:GH},
    {link: "inwentaryzacje", name: "Inwentaryzacje", icon:GI},
    {link: "realizacje-ogrodow", name: "Realizacje ogrodów", icon:GJ},
    {link: "pielegnacja", name: "Pielęgnacja", icon:GK},
    {link: "nadzory", name: "Nadzory", icon: GL}];

const interiors = [
    {link: "projekty", name: "Projekty", icon: IA},
    {link: "uklad-funkcjonalny", name: "Układ funkcjonalny", icon: IB},
    {link: "oswietlenie", name: "Oświetlenie", icon: IC},
    {link: "podlogi", name: "Podłogi", icon: ID},
    {link: "sufity-podwieszane", name: "Sufity podwieszane", icon: IE},
    {link: "meble-na-wymiar", name: "Meble na wymiar", icon: IF},
    {link: "elementy-dekoracyjne", name: "Elementy Dekoracyjne", icon: IG},
    {link: "zielone-wnetrza", name: "Zielone wnętrza", icon: IH},
    {link: "nadzory", name: "Nadzory", icon: II}];

const OfferBlock = () => {

    const offerBlockInteriors = interiors.map(interior => (
        <Link to={`/oferta/wnetrza/${interior.link}`}  key={interior.link} className='offerBlock__box-link' onClick={()=> window.scrollTo(0,0)}>
            <div key={interior.link} className='offerBlock__box-icon'>
                <img className='offerBlock__box-icon-img' src={interior.icon} alt=""/>
                <p className='offerBlock__box-icon-text'>{interior.name}</p>
            </div>
        </Link>
    ));

    const offerBlockGarden = gardens.map(garden => (
        <Link to={`/oferta/ogrody/${garden.link}`}  key={garden.link} className='offerBlock__box-link' onClick={()=> window.scrollTo(0,0)}>
            <div key={garden.link} className='offerBlock__box-icon'>
                <img className='offerBlock__box-icon-img' src={garden.icon} alt=""/>
                <p className='offerBlock__box-icon-text'>{garden.name}</p>
            </div>
        </Link>
    ));

    return (
        <div className='offerBlock'>
            <aside className='offerBlock__background offerBlock__aside'>
                <h1 className='offerBlock__title'>WNĘTRZA</h1>
                <div className='offerBlock__box'>
                    <h1 className='offerBlock__title offerBlock__box-header'>WNĘTRZA</h1>
                    {offerBlockInteriors}
                </div>
            </aside>
            <section className='offerBlock__background offerBlock__section'>
                <h1 className='offerBlock__title'>OGRODY</h1>
                <div className='offerBlock__box'>
                    <h1 className='offerBlock__title offerBlock__box-header'>OGRODY</h1>
                    {offerBlockGarden}
                </div>
            </section>
        </div>
    )
};

export default OfferBlock;