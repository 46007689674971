import React from "react";
import '../styles/PrivacyPolicy.css'


const PrivacyPolicy = () => {
    return (
        <div className='privacyPolicy'>
            <div className='privacyPolicy__header'>
                <h1>Polityka prywatności</h1>
                <ol>
                    <li>POSTANOWIENIA OGÓLNE</li>
                    <ol>
                        <li>Administratorem danych osobowych zbieranych za pośrednictwem strony
                            internetowej
                            jest Patryk Piórkowski wykonujący/a
                            działalność gospodarczą pod firmą PIÓRKOWSKI PRACOWNIA PROJEKTOWA, adres
                            siedziby:
                            Antoniewo 30, adres do
                            doręczeń: Antoniewo 30, NIP: 5030084240, REGON: 386204290, wpisaną do
                            Centralnej
                            Ewidencji i Informacji o Działalności
                            Gospodarczej, adres poczty elektronicznej:
                            pracownia.piorkowski@gmail.com, dalej
                            „Administrator”, będący/a jednoczeście
                            Usługodawcą. , miejsce wykonywania działalności: Antoniewo 30, adres do
                            doręczeń:
                            Antoniewo 30, NIP: 5030084240,
                            REGON: 386204290, adres</li>
                        <li>Dane osobowe zbierane przez Administratora za pośrednictwem strony
                            internetowej są
                            przetwarzane zgodnie z
                            Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
                            kwietnia
                            2016 r. w sprawie ochrony osób
                            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                            swobodnego
                            przepływu takich danych oraz uchylenia
                            dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane
                            dalej RODO
                            oraz
                            ustawą o ochronie danych
                            osobowych z dnia 10 maja 2018 r.</li>
                    </ol>
                    <li>RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH, CEL I ZAKRES ZBIERANIA DANYCH</li>
                    <ol>
                    <li>CEL PRZETWARZANIA I PODSTAWA PRAWNA. Administrator przetwarza dane osobowe
                        za
                        pośrednictwem strony w
                        przypadku:</li>
                        <ol>
                    <li>skorzystania przez użytkownika z formularza kontaktowego. Dane osobowe są
                        przetwarzane na podstawie art. 6
                        ust. 1 lit. f) RODO jako prawnie usprawiedliwiony interes
                        Administratora.</li>
                        </ol>
                    <li>RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH. Administrator przetwarza
                        następujące
                        kategorie danych
                        osobowych użytkownika:</li>
                        <ol>
                    <li>Imię i nazwisko,</li>
                    <li>Adres (zamieszkania),</li>
                    <li>Adres e-mail,</li>
                    <li>Numer telefonu,</li>
                        </ol>
                    <li>OKRES ARCHIWIZACJI DANYCH OSOBOWYCH. Dane osobowe użytkowników
                        przechowywane
                        są
                        przez Administratora:</li>
                        <ol>
                    <li>w przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy, tak
                        długo,
                        jak jest to niezbędne do
                        wykonania umowy, a po tym czasie przez okres odpowiadający okresowi
                        przedawnienia
                        roszczeń. Jeżeli przepis
                        szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla
                        roszczeń o świadczenia okresowe
                        oraz roszczeń związanych z prowadzeniem działalności gospodarczej - trzy
                        lata.</li>
                    <li>w przypadku, gdy podstawą przetwarzania danych jest zgoda, tak długo, aż
                        zgoda
                        nie
                        zostanie odwołana, a po
                        odwołaniu zgody przez okres czasu odpowiadający okresowi przedawnienia
                        roszczeń
                        jakie może podnosić
                        Administrator i jakie mogą być podnoszone wobec niego. Jeżeli przepis
                        szczególny
                        nie
                        stanowi inaczej, termin
                        przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz
                        roszczeń
                        związanych z
                        prowadzeniem działalności gospodarczej - trzy lata.</li>
                        </ol>
                    <li>Podczas korzystania ze strony internetowej mogą być pobierane dodatkowe
                        informacje, w szczególności: adres IP przypisany
                        do komputera użytkownika lub zewnętrzny adres IP dostawcy Internetu, nazwa
                        domeny,
                        rodzaj przeglądarki, czas dostępu,
                        typ systemu operacyjnego.</li>
                    <li>Od użytkowników mogą być także gromadzone dane nawigacyjne, w tym
                        informacje o
                        linkach i odnośnikach, w które
                        zdecydują się kliknąć lub innych czynnościach, podejmowanych na stronie
                        internetowej. Podstawą prawną tego rodzaju
                        czynności jest prawnie uzasadniony interes Administratora (art. 6 ust. 1
                        lit. f
                        RODO), polegający na ułatwieniu korzystania z
                        usług świadczonych drogą elektroniczną oraz na poprawie funkcjonalności tych
                        usług.</li>
                    <li>Podanie danych osobowych przez użytkownika jest dobrowolne.</li>
                    <li>Dane osobowe będą przetwarzane także w sposób zautomatyzowany w formie
                        profilowania, o ile użytkownik wyrazi na to
                        zgodę na podstawie art. 6 ust. 1 lit. a) RODO. Konsekwencją profilowania
                        będzie
                        przypisanie danej osobie profilu w celu
                        podejmowania dotyczących jej decyzji bądź analizy lub przewidywania jej
                        preferencji,
                        zachowań i postaw.</li>
                    <li>Administrator dokłada szczególnej staranności w celu ochrony interesów
                        osób,
                        których dane dotyczą, a w szczególności
                        zapewnia, że zbierane przez niego dane są:</li>
                        <ol>
                    <li>przetwarzane zgodnie z prawem,</li>
                    <li>zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu
                        przetwarzaniu niezgodnemu z
                        tymi celami,</li>
                    <li>merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są
                        przetwarzane
                        oraz przechowywane w
                        postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż
                        jest
                        to
                        niezbędne do osiągnięcia celu
                        przetwarzania.</li>
                        </ol>
                    </ol>
                    <li>UDOSTĘPNIENIE DANYCH OSOBOWYCH</li>
                    <ol>
                    <li>Dane osobowe użytkowników przekazywane są dostawcom usług, z których
                        korzysta
                        Administrator przy prowadzeniu strony
                        internetowej. Dostawcy usług, którym przekazywane są dane osobowe, w
                        zależności
                        od
                        uzgodnień umownych i okoliczności,
                        albo podlegają poleceniom Administratora co do celów i sposobów
                        przetwarzania
                        tych
                        danych (podmioty przetwarzające) albo
                        samodzielnie określają cele i sposoby ich przetwarzania
                        (administratorzy).</li>
                    <li>Dane osobowe użytkowników są przechowywane wyłącznie na terenie
                        Europejskiego
                        Obszaru Gospodarczego (EOG).</li>
                    </ol>
                    <li>PRAWO KONTROLI, DOSTĘPU DO TREŚCI WŁASNYCH DANYCH ORAZ ICH POPRAWIANIA</li>
                    <ol>
                    <li>Osoba, której dane dotyczą, ma prawo dostępu do treści swoich danych
                        osobowych
                        oraz prawo ich sprostowania, usunięcia,
                        ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia
                        sprzeciwu, prawo do cofnięcia zgody w
                        dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego
                        dokonano na podstawie zgody przed jej
                        cofnięciem.</li>
                    <li>Podstawy prawne żądania użytkownika:</li>
                        <ol>
                    <li>Dostęp do danych – art. 15 RODO</li>
                    <li>Sprostowanie danych – art. 16 RODO.</li>
                    <li>Usunięcie danych (tzw. prawo do bycia zapomnianym) – art. 17 RODO.</li>
                    <li>Ograniczenie przetwarzania – art. 18 RODO.</li>
                    <li>Przeniesienie danych – art. 20 RODO.</li>
                    <li>Sprzeciw – art. 21 RODO</li>
                    <li>Cofnięcie zgody – art. 7 ust. 3 RODO.</li>
                        </ol>
                    <li>W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać stosowną
                        wiadomość e-mail na adres:
                        pracownia.piorkowski@gmail.com.</li>
                    <li>W sytuacji wystąpienia przez użytkownika z uprawnieniem wynikającym z
                        powyższych praw, Administrator spełnia żądanie
                        albo odmawia jego spełnienia niezwłocznie, nie później jednak niż w ciągu
                        miesiąca po jego otrzymaniu. Jeżeli jednak - z
                        uwagi na skomplikowany charakter żądania lub liczbę żądań – Administrator
                        nie
                        będzie mógł spełnić żądania w ciągu
                        miesiąca, spełni je w ciągu kolejnych dwóch miesięcy informując użytkownika
                        uprzednio w terminie miesiąca od otrzymania
                        żądania - o zamierzonym przedłużeniu terminu oraz jego przyczynach.</li>
                    <li>W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza
                        przepisy
                        RODO, osoba, której dane dotyczą, ma
                        prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.</li>
                    </ol>
                    <li>PLIKI "COOKIES"</li>
                    <ol>
                    <li>Strona Administratora używa plików „cookies”.</li>
                    <li>Instalacja plików „cookies” jest konieczna do prawidłowego świadczenia
                        usług
                        na stronie internetowej. W plikach „cookies"
                        znajdują się informacje niezbędne do prawidłowego funkcjonowania strony, a
                        także
                        dają one także możliwość opracowywania
                        ogólnych statystyk odwiedzin strony internetowej.</li>
                    <li>W ramach strony stosowane są rodzaje plików "cookies": sesyjne</li>
                        <ol>
                    <li>„Cookies” „sesyjne” są plikami tymczasowymi, które przechowywane są w
                        urządzeniu końcowym użytkownika do
                        czasu wylogowania (opuszczenia strony).</li>
                        </ol>
                    <li>Administrator wykorzystuje własne pliki cookies w celu lepszego poznania
                        sposobu interakcji użytkownika w zakresie
                        zawartości strony. Pliki gromadzą informacje o sposobie korzystania ze
                        strony
                        internetowej przez użytkownika, typie strony, z
                        jakiej użytkownik został przekierowany oraz liczbie odwiedzin i czasie
                        wizyty
                        użytkownika na stronie internetowej. Informacje
                        te nie rejestrują konkretnych danych osobowych użytkownika, lecz służą do
                        opracowania statystyk korzystania ze strony.</li>
                    <li>Użytkownik ma prawo zadecydowania w zakresie dostępu plików „cookies” do
                        swojego komputera poprzez ich uprzedni
                        wybór w oknie swojej przeglądarki. Szczegółowe informacje o możliwości i
                        sposobach obsługi plików „cookies” dostępne są
                        w ustawieniach oprogramowania (przeglądarki internetowej).</li>
                    </ol>
                    <li>POSTANOWIENIA KOŃCOWE</li>
                    <ol>
                    <li>Administrator stosuje środki techniczne i organizacyjne zapewniające
                        ochronę
                        przetwarzanych danych osobowych
                        odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w
                        szczególności zabezpiecza dane przed ich
                        udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną,
                        przetwarzaniem z naruszeniem
                        obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub
                        zniszczeniem.</li>
                    <li>Administrator udostępnia odpowiednie środki techniczne zapobiegające
                        pozyskiwaniu i modyfikowaniu przez osoby
                        nieuprawnione, danych osobowych przesyłanych drogą elektroniczną.</li>
                    <li>W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się
                        odpowiednio przepisy RODO oraz inne właściwe
                        przepisy prawa polskiego.</li>
                    </ol>
                </ol>
            </div>
        </div>
    )
};

export default PrivacyPolicy