import React, {Component} from 'react';
import '../styles/App.css';
import {BrowserRouter as Router, Link} from 'react-router-dom'
import Navigation from "./Navigation";
import Page from "./Page";
import Footer from "./Footer";
import CookieConsent from "react-cookie-consent";

class App extends Component {

    render() {
        return (
            <Router>
                <div className='App'>
                    <header>
                        <Navigation/>
                    </header>
                    <section>
                        <Page/>
                    </section>
                    <footer>
                        <Footer/>
                    </footer>
                    <CookieConsent buttonText="Akceptuje" buttonStyle={{margin: 'auto 15px'}}>
                        W celu dostarczania naszych usług wykorzystujemy pliki cookies. Aby
                        dowiedzieć się więcej o szczegółach plików cookies kliknij
                        <Link style={{color: 'rgb(255, 212, 45)', textDecoration: 'none', fontSize: '16px'}}
                        to={'polityka-prywatnosci'}> tutaj</Link> Korzystanie z naszego serwisu
                        internetowego traktowane jest jako zgoda na politykę przetwarzania danych
                        osobowych.
                    </CookieConsent>
                </div>
            </Router>
        );
    }
}

export default App;
