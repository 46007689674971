import React from "react";
import '../styles/SubPageHeader.css'

class SubPageHeader extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.subPageRef = React.createRef()
    }

    handleShow= () =>{
        const subPageRef = this.subPageRef.current;
        if (subPageRef.getBoundingClientRect().top > window.innerHeight){
            subPageRef.classList.remove('SubPageHeader-show')
        } else {
            subPageRef.classList.add('SubPageHeader-show')
        }
    };

    componentDidMount() {
        this.subPageRef.current.classList.add('SubPageHeader-show');
        window.addEventListener('scroll', this.handleShow)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleShow)
    }

    render() {
        return (
            <div className='SubPageHeader' ref={this.subPageRef}>
                <i className="fas fa-angle-down SubPageHeader-top"> </i>
                <p className='SubPageHeader-center'>{this.props.center}</p>
                <p className='SubPageHeader-bottom'>{this.props.bottom}</p>
            </div>
        )
    }
}

export default SubPageHeader;