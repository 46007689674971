import React from "react";
import {Route, Switch} from "react-router-dom"

import HomePage from "../pages/HomePage";
import AboutUs from "../pages/AboutUs";
import Offer from "../pages/Offer";
import OfferGardens from "../components/OfferGardens";
import OfferInteriors from "../components/OfferInteriors";
import Portfolio from "../pages/Portfolio";
import ProjectPage from "../components/ProjectPage";
import Project from "../pages/Project";
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const Page = () => {
    return (
        <>
            <Switch>
                <Route path='/' exact component={HomePage}/>
                <Route path='/o-firmie' component={AboutUs}/>
                <Route path='/oferta' exact component={Offer}/>
                <Route path='/oferta/wnetrza/:link' component={OfferInteriors}/>
                <Route path='/oferta/ogrody/:link' component={OfferGardens}/>
                <Route path='/portfolio' exact component={Portfolio}/>
                <Route path='/portfolio/:link' component={ProjectPage}/>
                <Route path='/projekty' component={Project}/>
                <Route path='/kontakt' component={Contact}/>
                <Route path='/polityka-prywatnosci' component={PrivacyPolicy}/>
            </Switch>
        </>
    )
};

export default Page