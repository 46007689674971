import React from "react";
import SubPageHeader from "../components/SubPageHeader";
import PortfolioBlock from "../components/PortfolioBlock";
import '../styles/PortfolioPage.css'


const projects = [
    {link: "dobrzyn4", desc: "NOWOCZESNE WNĘTRZA DOMU", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB1.jpg'},
    {link: "chelmno", desc: "OGRÓD REZYDENCJONALNY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC1.jpg'},
    {link: "dabcze", desc: "OGRÓD Z BASENEM", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG1.jpg'},
    {link: "warszawa7", desc: "OGRÓD WIELKOMIEJSKI", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI1.jpg'},
    {link: "warszawa6", desc: "OGRÓD MINIMALISTYCZNY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M1.jpg'},
    {link: "dobrzyn3", desc: "OGRÓD MIEJSKI", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W1.jpg'},
    {link: "bochnia", desc: "POD PALMAMI", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N1.jpg'},
    {link: "torun2", desc: "OOGRÓD MODERNISTYCZNY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y1.jpg'},
    {link: "poznan", desc: "OGRÓD ZABAW", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA1.jpg'},
    {link: "warszawa2", desc: "WNĘTRZE DOMU W WARSZAWIE", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z1.jpg'},
    {link: "warszawa5", desc: "OGRÓD RODZINNY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R1.jpg'},
    {link: "warszawa4", desc: "OGRÓD PRZYDOMOWY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S1.jpg'},
    {link: "warszawa3", desc: "OGRÓD BLIŹNIACZY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T1.jpg'},
    {link: "kowalewo", desc: "OGRÓD RZEŹB", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U1.jpg'},
    {link: "jablonowo", desc: "OGRÓD REKREACJI", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE1.jpg'},
    {link: "poznan2", desc: "OGRÓD WIELOPOZIOMOWY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF1.jpg'},
    {link: "tarnow", desc: "OGRÓD Z PATIO", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH1.jpg'},
    {link: "dobrzyn2", desc: "PRZESTRZEŃ OGÓLNODOSTĘPNA", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/X++++++PRZESTRZEN+OGOLNODOSTEPNA/X1.jpg'},
    {link: "ciechocin", desc: "OGRÓD W LEŚNYM OTOCZENIU", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/O+OGR%C3%93D+W+LE%C5%9ANYM+OTOCZENIU/O1.jpg'},
    {link: "zbojno", desc: "OGRÓD NA SKARPIE", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P1.jpg'},
    {link: "torun", desc: "OGRÓD GEOMETRYCZNY NOWOCZESNY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A1.jpg'},
    {link: "drweca", desc: "NATURALISTYCZNY TEREN ZIELENI ", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B1.jpg'},
    {link: "lazienki", desc: "PROJEKT ŁAZIENKI", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/D+++PROJEKT+%C5%81AZIENKI/D1.jpg'},
    {link: "pokoj", desc: "PROJEKT POKOJU NA PODDASZU ", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/E++++PROJEKT+POKOJU+NA+PODDASZU/E1.jpg'},
    {link: "podzamek", desc: "OGRÓD W MIEŚCIE", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/I+++++OGR%C3%93D+W+MIE%C5%9ACIE/I1.jpg'},
    {link: "warszawa", desc: "TARAS NA DACHU BUDYNKU", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J1.jpg'},
    {link: "jarczechowo", desc: "OGRÓD NA SKARPIE", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/L+++++OGR%C3%93D+NA+SKARPIE/L1.jpg'}
];

class Portfolio extends React.Component {

    render() {
        return (
            <>
                <div className='portfolioPage'>
                    <div className='portfolioPage__header'>
                        <SubPageHeader center={'portfolio'}
                                       bottom={'przejrzyj moje projekty'}/>
                    </div>
                    <PortfolioBlock projectList={projects}/>
                </div>
            </>
        )
    };
}

export default Portfolio