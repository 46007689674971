import React from "react";
import '../styles/OfferBlock.css'
import {Link} from "react-router-dom";
import OfferGardensContentOfOffer from "./OfferGardensContentOfOffer";

const OfferGardens = ({match}) => {
    return (
        <>
            <div className='offerContent'>
                <OfferGardensContentOfOffer id={match.params.link}/>
                <Link className='offerContent__button' to='/oferta' onClick={()=> window.scrollTo(0,0)}>powrót</Link>
            </div>
        </>
    )
};

export default OfferGardens