import React from "react";
import '../styles/Footer.css'

const Footer = () => {
    return (
        <>
            <div className='footer'>
                <div className='footer__logo'/>
                    <div className='footer__list'/>
                <div className="footer__media">
                    <a className='navi__list-media-link'
                       href="https://www.facebook.com/Pracownia-Projektowa-Pi%C3%B3rkowski-113250600219568/"
                       rel='noopener noreferrer'
                       target="_blank">
                        <i className="fab fa-facebook-square navi__list-media-ico"/></a>
                    <a className='navi__list-media-link'
                       href="https://www.instagram.com/patrykpiorkowski/?igshid=1d46fif26n5qz&fbclid=IwAR2UMTBQpHCcvZuCK7Yts_bNlcLKqoTdhXqowVIkKZaRWAmtj4_PmkEb6Q4"
                       rel='noopener noreferrer'
                       target="_blank">
                        <i className="fab fa-instagram navi__list-media-ico"/></a>
                    <a className='navi__list-media-link'
                       href="https://www.youtube.com/channel/UCV29KmOWtbgv_LMM_D7m3Qg"
                       rel='noopener noreferrer'
                       target="_blank">
                        <i className="fab fa-youtube navi__list-media-ico"/></a>
                </div>
            </div>
        </>
    )
};

export default Footer