import React from "react";
import '../styles/HomePage.css'
import OfferBlock from "../components/OfferBlock";
import SubPageHeader from "../components/SubPageHeader";
import AboutUsWhyUs from "../components/AboutUsWhyUs";
import PortfolioBlock from "../components/PortfolioBlock";
import ContactCard from "../components/ContactCard";
import {Link} from "react-router-dom";


class HomePage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.homePageSlider = [];
        this.homePageTextBox = [];
        this.homePageDot = [];
        // eslint-disable-next-line no-unused-vars
        let auto = this.auto;
    }

    slider = [
        {
            id: 0,
            img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/g%C5%82%C3%B3wna/gl1.jpg',
            text: 'PROJEKTOWANIE',
            text2: 'OGRODÓW',
            text3: 'Oferuję niepowtarzalne, unikatowe projektowanie ogrodów.'
        },
        {
            id: 1,
            img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/g%C5%82%C3%B3wna/gl2.jpg',
            text: 'PROJEKTOWANIE ',
            text2: 'WNĘTRZ',
            text3: 'Projektuję ponadczasowe, a co najważniejsze funkcjonalne wnętrza.'
        },
        {
            id: 2,
            img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/g%C5%82%C3%B3wna/gl3.jpg',
            text: 'PROJEKTOWANIE  ',
            text2: 'ZDALNE',
            text3: 'Działam na terenie całego kraju.'
        },
        {
            id: 3,
            img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/g%C5%82%C3%B3wna/gl4.jpg',
            text: 'ZIELEŃ  ',
            text2: 'WE WNĘTRZACH',
            text3: 'Wprowadzę piękno natury do Twoich pomieszczeń.'
        },
        {
            id: 4,
            img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/g%C5%82%C3%B3wna/gl5.jpg',
            text: 'ZAKŁADANIE  ',
            text2: 'OGRODÓW',
            text3: 'Pomogę Ci stworzyć wymarzoną przestrzeń.'
        },
        {
            id: 5,
            img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/g%C5%82%C3%B3wna/gl6.jpg',
            text: 'PIELĘGNACJA  ',
            text2: 'OGRODÓW',
            text3: 'Zatroszczę się o to, aby Twój ogród już zawsze dawał Ci radość.'
        }];

    projects = [
        {link: "warszawa6", desc: "OGRÓD MINIMALISTYCZNY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M1.jpg'},
        {link: "dobrzyn3", desc: "OGRÓD MIEJSKI", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W1.jpg'},
        {link: "bochnia", desc: "POD PALMAMI", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N1.jpg'},
        {link: "torun2", desc: "OOGRÓD MODERNISTYCZNY", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y1.jpg'},
        {link: "poznan", desc: "OGRÓD ZABAW", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA1.jpg'},
        {link: "warszawa2", desc: "WNĘTRZE DOMU W WARSZAWIE", img: 'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z1.jpg'}
    ];

    handleChangeDisplayElement() {
        const images = this.homePageSlider;
        const textBox = this.homePageTextBox;
        const dots = this.homePageDot;

        const changeTime = 5000;
        let active = 0;
        images[active].classList.add('homePage__active');
        textBox[active].classList.add('homePage__active');
        dots[active].classList.add('homePage__active');

        const autoChange = () => {
            active++;
            changeSlider();
        };

        const activeElem = (elem, active) => {
            const activeElement = elem.findIndex(e => e.classList.contains('homePage__active'));
            elem[activeElement].classList.remove('homePage__active');
            elem[active].classList.add('homePage__active');
        };

        const changeSlider = () => {
            if (active === images.length) {
                active = 0;
            } else if (active < 0) {
                active = images.length - 1;
            }

            activeElem(images, active);
            activeElem(textBox, active);
            activeElem(dots, active);
        };

        this.homePageDot.forEach(dot => dot.addEventListener('click', elem => {
            clearInterval(this.auto);
            active = elem.target.id;
            changeSlider();
            this.auto = setInterval(autoChange, changeTime)
        }));

        this.auto = setInterval(autoChange, changeTime);
    }

    componentDidMount() {
        this.handleChangeDisplayElement();
    }

    componentWillUnmount() {
        clearInterval(this.auto);
    }

    images = this.slider.map(image => (
        <img key={image.img.toString()} className='homePage__section-slider'
             ref={(slider) => this.homePageSlider.push(slider)} src={image.img} alt=''/>
    ));

    texts = this.slider.map(text => (
        <div key={text.text} className='homePage__aside-textBox'
             ref={textBox => this.homePageTextBox.push(textBox)}>
            <div className='homePage__aside-textBox-border'/>
            <p className='homePage__aside-textBox-text'>{text.text}</p>
            <p className='homePage__aside-textBox-text'>{text.text2}</p>
            <p className='homePage__aside-textBox-text'>{text.text3}</p>
        </div>
    ));

    dots = this.slider.map(dot => (
        <span key={dot.id.toString()} id={dot.id} className='homePage__aside-dots-dot'
              ref={dot => this.homePageDot.push(dot)}> </span>
    ));

    render() {
        return (
            <>

                <div className='homePage'>
                    <aside className='homePage__aside'>
                        {this.texts}
                        <div className='homePage__aside-dots'>
                            {this.dots}
                        </div>
                    </aside>
                    <section className='homePage__section'>
                        {this.images}
                    </section>
                </div>
                <SubPageHeader center={'dlaczego piórkowski?'}
                               bottom={'skorzystaj z usług profesjonalisty'}/>
                <AboutUsWhyUs/>
                <SubPageHeader center={'POZNAJ OFERTĘ'}
                               bottom={'WYBIERZ TO, CO DLA CIEBIE NAJLEPSZE'}/>
                <OfferBlock/>
                <SubPageHeader center={'portfolio'}
                               bottom={'przejrzyj moje projekty'}/>
                <PortfolioBlock projectList={this.projects}/>
                <Link className='portfolioPage__more' to='/portfolio'
                      onClick={() => window.scrollTo(0, 0)}>więcej</Link>
                <ContactCard/>
            </>
        )
    };
}

export default HomePage