import React from "react";
import '../styles/OfferPage.css'
import SubPageHeader from '../components/SubPageHeader'
import OfferBlock from "../components/OfferBlock";

const Offer = () => {
    return (
        <div className='offerPage'>
            <div className='offerPage__header'>
                <SubPageHeader center={'poznaj ofertę'} bottom={'wybierz to, co dla ciebie najlepsze'}/>
            </div>
            <OfferBlock/>
        </div>
    )
};

export default Offer