import React from "react";
import "../styles/AboutUsWhyUs.css"

class AboutUsWhyUs extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.boxRef = [];
        this.descRef = React.createRef()
    }

    whyus = [
        {
            icon: 'fas fa-pencil-ruler aboutUsPage__whyUs-box-icon',
            header: 'PASJA',
            text: 'Projektowanie ogrodów i wnętrz to moja pasja. Wkładam całe serce w to, aby projekty, spełniały oczekiwania nawet najbardziej wymagających klientów.',
        },
        {
            icon: 'fas fa-paper-plane aboutUsPage__whyUs-box-icon',
            header: 'OTWARTOŚĆ',
            text: 'Jestem otwarty na Twoje sugestie oraz chętnie odpowiem na wszelkie pytania. Zapewniam łatwy oraz szybki kontakt.',
        },
        {
            icon: 'fas fa-ruler-combined aboutUsPage__whyUs-box-icon',
            header: 'PROFESJONALIZM',
            text: 'Projekt oddaję w Twoje ręce w formie książki zawierającej wszystkie opisy, rysunki oraz wizualizacje potrzebne do realizacji Twoich marzeń.',
        },
        {
            icon: 'far fa-grin-beam aboutUsPage__whyUs-box-icon',
            header: 'ZADOWOLENIE',
            text: 'Najważniejsze jest zadowolenie Klienta. \n' +
                'Pokażę Ci, jak sprawić aby Twój dom / ogród stał się miejscem w którym poczujesz się komfortowo.',
        },
    ];

    handleShow = () => {
        const boxRef = this.boxRef;
        boxRef.forEach(ref => {
            if (ref.getBoundingClientRect().top - 200 < window.innerHeight) {
                ref.classList.add('aboutUsPage__whyUs-box-show')
            } else {
                ref.classList.remove('aboutUsPage__whyUs-box-show')
            }
        });

        const descRef = this.descRef.current;
        if (descRef.getBoundingClientRect().top < window.innerHeight - descRef.scrollHeight) {
            descRef.classList.add('aboutUsPage__desc-show')
        } else {
            descRef.classList.remove('aboutUsPage__desc-show')
        }
    };

    componentDidMount() {
        this.handleShow();
        window.addEventListener('scroll', this.handleShow)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleShow)
    }

    contents = this.whyus.map(content => (
        <div key={content.icon} className='aboutUsPage__whyUs-box'
             ref={boxRef => this.boxRef.push(boxRef)}>
            <i className={content.icon}/>
            <h1 className='aboutUsPage__whyUs-box-header'>{content.header}</h1>
            <p className='aboutUsPage__whyUs-box-text'>{content.text}</p>
            <div className='aboutUsPage__whyUs-box-line'/>
        </div>
    ));

    render() {
        return (
            <>
                <p className='aboutUsPage__desc' ref={this.descRef}>Chcesz zmienić coś w swoim
                    otoczeniu, jednak nie wiesz jak się do tego zabrać? Poszukujesz kogoś, kto
                    pomoże zrealizować Twoje marzenia o pięknym ogrodzie i oryginalnym wnętrzu?
                    Pracownia Projektowa Piórkowski zmieni Twoje życie, pomoże znaleźć najlepsze
                    rozwiązania oraz sprawi, że już nigdy nie spojrzysz na swój ogród / dom w taki
                    sam sposób.</p>
                <div className='aboutUsPage__whyUs'>
                    {this.contents}
                </div>
            </>
        )
    }
}

export default AboutUsWhyUs
