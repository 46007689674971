import React from 'react';
import SubPageHeader from './SubPageHeader'


class OfferGardensContentOfOffer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.imgRef = React.createRef();
    }

    handleStick = () => {
        const imgRef = this.imgRef.current;
        if (window.scrollY >= window.innerWidth * 0.13) {
            imgRef.classList.add('offerContent__aside-stick')
        } else {
            imgRef.classList.remove('offerContent__aside-stick')
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleStick)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleStick)
    }

    render() {
        if (this.props.id === 'projekty') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader center={'projektowanie ogrodów'}
                                       bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Projekt</h1>
                        <p className='offerContent__text-normal'>Ogród to miejsce, w którym można odpocząć po ciężkim
                            dniu w pracy, skorzystać z pięknej pogody i oddać się błogiemu relaksowi. Zanim jednak ogród
                            zacznie spełniać funkcję osobistej oazy, należy o niego odpowiednio zadbać. Świadome
                            projektowanie ogrodów nie jest rzeczą łatwą, dlatego warto skorzystać z usług
                            profesjonalisty. Powstanie spójnej kompozycji, zgodnej z wizją inwestorów oraz spełnienie
                            ich wszystkich marzeń wymaga ogromnego doświadczenia. Stworzę specjalnie dla Ciebie
                            indywidualny projekt tak, aby Twój ogród pełnił nie tylko funkcję ozdobną ale również
                            użytkową. Rozwiązania przedstawione w projekcie oraz dobór wszystkich roślin będzie
                            dopasowany w taki sposób, by umożliwiał Ci samodzielną pielęgnację. Najważniejsze jest dla
                            mnie Twoje zadowolenie! Zapraszam w tym miejscu do zapoznania się z moim portfolio - w celu
                            zaczerpnięcia inspiracji. Z pewnością spodobają Ci się niektóre pomysły i rozwiązania.</p>
                        <p className='offerContent__text-normal'>Jednak Pracownia Projektowa Piórkowski nie zajmuje się
                            tylko i wyłącznie klientem indywidualnym. </p>
                        <p className='offerContent__text-bold'>Oferuję również :</p>
                        <ul className='offerContent__text-list'>
                            <li>Projekty zieleni reprezentacyjnej wokół siedzib firm</li>
                            <li>Projekty ogrodów letnich przy restauracjach, pubach czy domach weselnych</li>
                            <li>Projekty terenów rekreacyjno-wypoczynkowych</li>
                            <li>Projekty zielonych ścian i ogrodów wertykalnych</li>
                            <li>Projekty zieleńców, parków oraz innych form zieleni</li>
                            <li>Projekty rewaloryzacji historycznych założeń ogrodowych i parkowych</li>
                            <li>Inwentaryzacje dendrologiczne, rozpoznawanie gatunków krzewów i drzew</li>
                            <li>Projekty ogrodów sensorycznych i edukacyjnych dla szkół</li>
                            <li>Projekty zieleni reprezentacyjnej wokół instytucji publicznych</li>
                            <li>Projekty zieleni osiedlowej</li>
                        </ul>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O1.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'elewacja') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'elewacja'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Elewacja</h1>
                        <p className='offerContent__text-normal'>Elewacja domu, budynku czy firmy pokazuje odbiorcom
                            którzy ją oglądają z kim tak naprawdę mają do czynienia. Dlatego tak ważne jest, by jej
                            wykonanie współgrało z najnowszymi trendami ale również odzwierciedlało duszę właściciela.
                            Powierzenie projektu elewacji zagwarantuje Ci ujrzenie swojego budynku w zupełnie nowej,
                            odświeżonej i wyjątkowej odsłonie. Dzięki wieloletniemu doświadczeniu pomogę Ci w tym, aby
                            Twój dom czy budynek firmy budził zachwyt wśród przechodniów oraz zachęcał ich do wejścia.
                            Zaproponuję Ci rozwiązania z wytrzymałych materiałów zgodnych z duchem nowoczesnego
                            projektowania elewacji. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O2.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'oswietlenie') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Oświetlenie'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Oświetlenie</h1>
                        <p className='offerContent__text-normal'>Posiadanie pięknego ogrodu nie może się obejść bez
                            możliwości jego prezentacji w nocnej odsłonie. Oświetlenie nowoczesnych ogrodów wymaga
                            dokładnego przemyślenia. Brak dostosowania światła do przestrzeni oraz jego zbyt duża ilość
                            może spowodować zaburzenie naturalnego piękna projektowanej przestrzeni. Dobrze dobrane
                            oświetlenie pomoże nadać Twojemu wymarzonemu ogrodowi odrobinę magii i tajemniczości. W
                            przypadku oświetlenia ogrodu - więcej nie zawsze znaczy lepiej. Projekt jaki dla Ciebie
                            wykonam sprawi, że ogród będzie wymarzoną strefą do odpoczynku nie tylko w świetle dziennym,
                            ale również w ciepłe letnie wieczory i noce. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O3.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'ogrodzenia') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Ogrodzenia'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Ogrodzenia</h1>
                        <p className='offerContent__text-normal'>Każdy dom potrzebuje ogrodzenia podkreślającego jego
                            charakter oraz wyrazistość. Nowoczesne projekty ogrodzeń zawierają znacznie więcej
                            elementów, niż rozmieszczenie furtki czy bram w sposób funkcjonalny. Okazuje się, że dobrze
                            przemyślane oraz wykonane ogrodzenie będzie spełniało swoje walory reprezentacyjne przez
                            wiele długich lat. Inspiracje oraz informacje zamieszczone na różnych forach nie zawsze będą
                            miały zastosowanie do Twojego terenu oraz po wykonaniu współgrały z Twoim domem oraz
                            ogrodem. Projekt ogrodzenia pozwoli uniknąć sytuacji wykładania kolejnych środków
                            finansowych na jego przebudowę lub całkowitą wymianę.</p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O4.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'nawierzchnia') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Nawierzchnia'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Nawierzchnia</h1>
                        <p className='offerContent__text-normal'>Nawierzchnia jest jednym z istotniejszych elementów
                            każdego projektu. Podczas jej projektowania najważniejszym aspektem jest zadanie które ma
                            spełniać. Zupełnie inaczej będzie wyglądał projekt nawierzchni przeznaczony wyłącznie do
                            korzystania przez pieszych, a zupełnie inaczej kiedy w grę wchodzi również podjazd dla
                            samochodów lub przygotowanie projektu powierzchni pod taras. Dobór materiałów z których
                            zostanie wykonana nawierzchnia, nie jest rzeczą łatwą. Znalezienie na rynku spośród tak
                            wielu firm oferujących produkty wysokiej jakości, może okazać się bardzo czasochłonne.
                            Dlatego lepiej nie tracić czasu i skorzystać z kompleksowej opcji projektu nawierzchni. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O5.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'tarasy-i-balkony') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Tarasy i balkony'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Tarasy i balkony</h1>
                        <p className='offerContent__text-normal'>Tarasy oraz balkony są nieodłączną częścią domów. W
                            ostatnich latach ich reprezentatywność nabrała szczególnego znaczenia. Różnorodność
                            rozwiązań jakie można zastosować oraz szeroka oferta materiałów, z których można je wykonać
                            wprowadza dezorientację. Dodatkowo taras czy balkon jako przedłużenie salonu powinien
                            spełniać funkcję wypoczynkową, rozrywkową oraz estetyczną. Wyposażenie tej przestrzeni w
                            meble, które nie są podatne na czynniki zewnętrze jak promieniowanie UV, wilgoć, skrajnie
                            niskie temperatury czy zanieczyszczenia, nie jest najłatwiejszym zadaniem. Świadomość
                            inwestorów powierzających zadanie aranżacji tarasów i balkonów jest coraz większa. Każdy
                            projekt jaki wykonuję jest innowacyjny oraz dostosowany do oczekiwań klienta, który będzie
                            cieszył się nim przez bardzo długi czas. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O6.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'mala-architektura') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Mała architektura'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Mała architektura</h1>
                        <p className='offerContent__text-normal'>Projektowanie małej architektury wymaga ogromnej
                            kreatywności. Współczesna architektura wciąż postępuje w swojej oryginalności, elegancji
                            oraz przemyślanym designu. Prawdziwy projekt małej architektury nie polega na wykorzystaniu
                            gotowych wzorców z katalogów dostępnych firm, lecz zaprezentowanie przez architekta swojej
                            własnej kreatywności. Jeżeli masz pomysł na stworzenie jakiegoś elementu do Twojego ogrodu,
                            jednak żadna firma nie oferuje produktu spełniającego Twoje oczekiwania, mogę zaprojektować
                            go dla Ciebie po przedstawieniu pomysłu oraz wytycznych. Mała architektura to nieograniczone
                            możliwości projektowe - indywidualny projekt na pewno spełni Twoje oczekiwania.</p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O7.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'zielone-sciany') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Zielone ściany'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Zielone ściany</h1>
                        <p className='offerContent__text-normal'>Zielone ściany stały się w ostatnich latach nowym,
                            rosnącym trendem w budownictwie. Są to ściany z roślinami mogące być pełne wszystkiego co
                            zielone – nie tylko roślin zielonych ale również i ziół! Podczas projektowania zielonych
                            ścian jedynymi ograniczeniami jest pomysł i wyobraźnia. Jest to idealna alternatywa dla
                            zielonych fasad wspinających się po ścianach budynków. W przypadku zielonych ścian można od
                            razu zamontować gotowy ogród i już od pierwszych chwil cieszyć się jego pięknem. Jeśli
                            potrzebujesz projektu zgodnego z duchem czasu oraz spełniającego funkcję wizualną – napisz
                            do mnie, a wspólnie stworzymy wymarzony projekt Twojego ogrodu. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O8.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'inwentaryzacje') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Inwentaryzacje'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Inwentaryzacje</h1>
                        <p className='offerContent__text-normal'>Inwentaryzacja zieleni stanowi niezbędną dokumentację
                            podczas projektowania ogrodu. Jest ona wykonywana w celu określenia rodzaju drzew i krzewów,
                            które już istnieją na danym terenie. Może ona być użyteczna podczas określania, które drzewa
                            znajdują się zbyt blisko przyszłego budynku. Na podstawie inwentaryzacji można określić,
                            które egzemplarze kolidują z planowaną inwestycją oraz zaplanować ich przesadzenie lub
                            wycinkę. Do wykonania inwentaryzacji potrzebuję od Ciebie aktualnej mapy terenu do celów
                            projektowych. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O9.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'realizacje-ogrodow') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Realizacje ogrodów'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Realizacje ogrodów</h1>
                        <p className='offerContent__text-normal'>Jesteś zainteresowany projektem ogrodu wraz z
                            wykonaniem? Pracownia Projektowa Piórkowski ma w swojej ofercie również taką opcję.
                            Realizacja ogrodu jest odwzorowaniem zaprojektowanego wcześniej terenu nad którym
                            pracowaliśmy. Przejmuję wszystkie obowiązki związane z budową, począwszy od zakupu wszelkich
                            materiałów budowlanych, roślin, małej architektury a skończywszy na oddaniu gotowego ogrodu,
                            z którego możesz od razu korzystać.</p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O10.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'pielegnacja') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Pielęgnacja'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Pielęgnacja</h1>
                        <p className='offerContent__text-bold'>PIELĘGNACJA WIOSENNA:</p>
                        <ul className='offerContent__text-list'>
                            <li>Przycinanie roślin, które tego wymagają</li>
                            <li>Uzupełnienie ściółki</li>
                            <li>Nawożenie roślin</li>
                            <li>Pielęgnacja trawnika (wertykulacja, nawożenie, dosiewanie)</li>
                            <li>Usunięcie okryć zimowych</li>
                        </ul>
                        <p className='offerContent__text-bold'>PIELĘGNACJA LETNIA:</p>
                        <ul className='offerContent__text-list'>
                            <li>Oczyszczenie przekwitłych pędów roślin</li>
                            <li>Nawożenie roślin</li>
                            <li>Odchwaszczanie</li>
                            <li>Pielęgnacja trawnika</li>
                            <li>Kontrolowanie stanu zdrowotnego roślin</li>
                        </ul>
                        <p className='offerContent__text-bold'>PIELĘGNACJA JESIENNA:</p>
                        <ul className='offerContent__text-list'>
                            <li>Przycinanie roślin, które tego wymagają</li>
                            <li>Jesienna pielęgnacja trawnika</li>
                            <li>Okrycie roślin, które tego wymagają</li>
                            <li>Porządki jesienne w ogrodzie</li>
                        </ul>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O11.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'nadzory') {
            return (
                <>
                    <div className='offerContent__header'>
                        <SubPageHeader
                            center={'Nadzory'}
                            bottom={'zapoznaj się z ofertą'}/>
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Nadzory</h1>
                        <p className='offerContent__text-normal'>Aby wykonywane prace budowlane były zgodne z projektem,
                            świadczę autorski nadzór nad pracami wykonawczymi ogrodów. Aby osiągnąć najlepszy efekt
                            realizacji, moja obecność i kontrola na budowie jest bardzo ważna. Pełniąc nadzór pomogę
                            również rozwiązać wszelkie dodatkowe niuanse, jakie zawsze mogą pojawić się w trakcie
                            prowadzenia prac.</p>
                    </article>
                    <br/>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/OGRODY/O12.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        }
    }
}

export default OfferGardensContentOfOffer;