import React from "react";
import '../styles/ProjectPage.css'

class ProjectPageContent extends React.Component {

    projectsContent = [
        {
            link: "dobrzyn3",
            desc: "OGRÓD MIEJSKI",
            location: "Golub - Dobrzyń / Kujawsko – Pomorskie ",
            area: 1600,
            assum: "\nOgród zaprojektowano o charakterze nowoczesno – naturalistycznym gdzie motywem przewodnim jest LAS. W założeniu projektowym kierowano się funkcją jaką ma spełniać. Przestrzeń podzielono na strefy funkcjonalne tj; strefa reprezentacyjna, wjazdowa, rekreacyjna, jadalna, użytkowa oraz wypoczynkowa. Wyróżniającą strefą jest część reprezentacyjna, która zaplanowana jest na bazie geometrycznej kompozycji. Istotną funkcją jest ścieżka biegnąca do głównego wejścia. W strefie tej znajduje się pokaźnych rozmiarów lustro z logo Pracowni właściciela. Zaplanowano miejsce na rzeźbę, która zostanie wykonana przez samego inwestora – Artystę. Strefa ta spełnia bardzo ważną funkcję, a mianowicie miejsce wystaw prac oraz spotkań plenerowych. W części wypoczynkowej znajduje się altana wykonana z drewna, w której znajduje się grill, pojemne szafki oraz duży stół. Patio z paleniskiem oraz ścianą wodną gdzie zastosowana została wylewka ze stali nierdzewnej jest zwieńczeniem kompozycji. Dobór gatunków roślin posiada cechy naturalistyczne/leśne. Duże ilości paproci, niskich krzewów oraz bylin o stonowanej barwie podkreślać będą charakter projektowanego miejsca. W założeniu przewidziane są trawy ozdobne wielu gatunków, które wprowadzą ruch do przestrzeni. Powiew najmniejszego wiatru będzie powodował przyjemny dla ucha szelest.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W10.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W11.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/W++++OGROD+MIEJSKI/W12.jpg']
        },
        {
            link: "torun2",
            desc: "OGRÓD MODERNISTYCZNY",
            location: "Toruń / Kujawsko - Pomorskie",
            area: 2700,
            assum: "\nGłównym założeniem projektowym było wykreowanie miejsca przyjaznego dla całej rodziny. Kompozycja założenia oparta na prostych kątach tworzy nowoczesny charakter przestrzeni. Wydzielono strefę reprezentacyjną, użytkową, wjazdową, rekreacyjną, jadalną, użytkową czy też strefę wypoczynkową. Główną dominantą w ogrodzie, jest wyróżniające się miejsce wypoczynkowe w samym centrum założenia. Miejsce to osłonięte jest wysoką roślinnością od drogi oraz chroni przed wiatrem. W projekcie znalazło się również miejsce dla najmłodszych. Nad tarasem zaprojektowana została konstrukcja stalowa z automatyczną żaluzją przeciwsłoneczną. Z tyłu działki zaplanowane jest miejsce na duży warzywnik. W projekcie wykorzystano wiele drzew, aby urozmaicić przestrzeń. ",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y10.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y11.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Y++++++OGROD+MODERNISTYCZNY/Y12.jpg']
        },
        {
            link: "poznan",
            desc: "OGRÓD ZABAW",
            location: "Poznań / Wielkopolskie",
            area: 420,
            assum: "\nProjektowanie niewielkiego ogrodu jest o wiele większym wyzwaniem, niż przygotowanie projektu wielkiej przestrzeni, gdyż po prostu jest w nim mało miejsca aby zmieścić wszystko. Wyznaczona została strefa reprezentacyjna, strefa wypoczynkowa oraz strefa użytkowa. Projekt skupia się na niewielkiej, starannie dobranej roślinności oraz dodatkach. Urządzenia zabawowe dla najmłodszych zostały wkomponowane w tkankę założenia. Ogród zyskał wydzielone miejsce w tylnej części ogrodu w którym umieszczony został składzik na narzędzia, palenisko, hamaki czy też liny do wspinania się dla dzieci.   ",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA10.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AA+++OGROD+ZABAW/AA11.jpg']
        },
        {
            link: "warszawa2",
            desc: "WNĘTRZE DOMU W WARSZAWIE",
            location: "Warszawa / Mazowieckie",
            area: 70,
            assum: "\nProjekt wnętrza domu stworzony został dla rodziny z dwójką dzieci. Aranżację wyróżniają szlachetne materiały, eleganckie detale oraz różnorodność wzorów i faktur. W projekcie dominują stonowane kolory zieleni, drewno w chłodnym wybarwieniu, dopełnieniem jest tapeta z ciekawym wzorem oraz detale w kolorze polerowanego złota. Dzięki tym wszystkim zabiegom wnętrze nabiera smaku i staje się niezwykle stylowe.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z10.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z11.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z12.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/Z+++++WNETRZE+DOMU+W+WARSZAWIE/Z13.jpg']
        },
        {
            link: "warszawa5",
            desc: "OGRÓD RODZINNY",
            location: "Warszawa / Mazowieckie",
            area: 550,
            assum: "\nPrzedmiotem opracowania jest ogród przydomowy znajdujący się w Warszawie w otoczeniu spokojnego osiedla domów jednorodzinnych. Głównym aspektem projektowym była funkcjonalność. Prosta geometryczna forma nadaje harmonijny układ kompozycyjny, przez co wnętrze ogrodu stało się uporządkowane oraz przejrzyste. Ogród zyskał nowoczesny przestronny taras. Zadaszenie tarasu wykonane z aluminium osłonięte panelami oraz materiałową markizą. Miejsce znalazła również ławka w otoczeniu paneli z cortenu. Od strony tylnej budynku wygospodarowane zostało miejsce dla dzieci oraz warzywnik w formie drewnianych skrzyń. Szata roślinna została dobrana tak, aby wymagała jak najmniej pielęgnacji. Wymienić można kosodrzewiny, lawendy, dużą ilość traw ozdobnych oraz lawendy.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R10.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/R+++++OGROD+RODZINNY/R11.jpg']
        },
        {
            link: "warszawa4",
            desc: "OGRÓD PRZYDOMOWY",
            location: "Warszawa / Mazowieckie",
            area: 550,
            assum: "\nPrzedmiotem opracowania jest ogród przydomowy znajdujący się na jednym z osiedli w Warszawie. Projekt mieści się na 550m2. Jest to prosta geometryczna forma założenia. Na projektowanym terenie znalazło się miejsce dla wiaty samochodowej oraz dużego tarasu. Zadaszenie tarasu to aluminiowa konstrukcja połączona z materiałowym zadaszeniem (markizą) otwieraną automatycznie. Konstrukcja tarasu przewiduje zawieszane huśtawki bądź hamaku ogrodowego. Nawierzchnia w części reprezentacyjnej oraz tarasu wykonana została z nowoczesnej kostki brukowej. Ścieżki kontrastują wielkością płyt 80x80 cm do kostki brukowej. Nawierzchnia zaplanowana jest w delikatnych odcieniach szarości. Ciekawym elementem koncepcji są trzy lustra ogrodowe, które urozmaicają przestrzeń. Zieleń dobrana została tak, aby nie wymagała zbyt dużego nakładu czasu na pielęgnację. Są to między innymi trawy ozdobne, hortensje, bukszpany, szałwie, rozchodniki oraz lawendy.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/S+++++OGROD+PRZYDOMOWY/S9.jpg']
        },
        {
            link: "warszawa3",
            desc: "OGRÓD BLIŹNIACZY",
            location: "Warszawa / Mazowieckie",
            area: 550,
            assum: "\nOgród znajdujący się na osiedlu domów bliźniaczych w Warszawie. Głównym aspektem projektowym była funkcjonalność. Na projektowanym terenie znalazło się miejsce dla altany, która została wykonana na aluminiowym stelażu. Altana posiada materiałowe zadaszenie chroniące przed niekorzystnymi warunkami atmosferycznymi. Przy altanie znajduje się miejsce na misę paleniskową wykonaną ze stali corten oraz rustykalne belki wykorzystane jako siedzisko ogrodowe. Przed budynkiem od strony frontowej usytuowane zostały duże donice, które zostaną wykonane na miejscu. W donicach planowane jest posadzenie dwóch buków pospolitych prowadzonych w formie parasola. Nawierzchnia przed domem wykonana została z kostki brukowej w delikatnym odcieniu szarości. Ścieżki ogrodowe oraz nawierzchnia altany to wytrzymałe drewno egzotyczne. Roślinność to głównie naturalna osłona przeciwwietrzna oraz odgrodzenie od sąsiada. W ogrodzie znajduje się duża ilość lawendy, traw ozdobnych, szałwii oraz jeżówek.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/T+++++OGROD+BLIZNIACZY/T8.jpg']
        },
        {
            link: "kowalewo",
            desc: "OGRÓD RZEŹB",
            location: "Kowalewo Pomorskie / Kujawsko – Pomorskie ",
            area: 3000,
            assum: "\nKoncepcja projektu zagospodarowania terenu oparta jest na geometrycznych kształtach. Ogród przydomowy mieści się w pobliżu małego lasu. Tuż za ogrodzeniem znajduje się również nieużytek na którym rosną gatunki rodzime, postanowiłem więc wykorzystać charakter tego miejsca w założeniu projektowym. W przestrzeni wyróżnić można strefę reprezentacyjną, wypoczynkową czy też rekreacyjną. W strefie wjazdowej główną dominantą jest Świerk serbski - Pendula w otoczeniu niskiej roślinności. Na planie okręgu w pobliżu znajduje się miejsce na rzeźbę oraz siedzisko. Z tyłu budynku zaprojektowany został drewniany taras w połączeniu z balkonem na piętrze. Wyznaczone zostało miejsce biesiadne z altaną oraz ogniskiem, które otoczone jest wysoką roślinnością. Szatę roślinną tworzą w dużej mierze drzewa takie jak brzozy pożyteczne, tulipanowce, robinie akacjowe, graby, klony, buki, sosny pospolite czy też himalajskie. Wyróżnić można dużą ilość krzewów hortensji, pęcherznic, krzewuszek czy lilaków, które będą tworzyły kolejne piętro założenia. Wyznaczone zostało miejsce rekreacyjne przeznaczone głównie dla dzieci na plac zabaw, basen ogrodowy, duży trawnik. Duża przestrzeń ogrodowa pozwoliła na wkomponowanie dość pokaźnego sadu.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/U+++++OGROD+RZEZB/U10.jpg']
        },
        {
            link: "dobrzyn2",
            desc: "PRZESTRZEŃ OGÓLNODOSTĘPNA",
            location: "Golub - Dobrzyń / Kujawsko – Pomorskie",
            area: 450,
            assum: "\nPrzedmiotem opracowania jest teren znajdujący się przy dwórfijewo w Golubiu-Dobrzyniu. Główną ideą założenia było stworzenie przytulnego miejsca wypoczynku w otoczeniu zieleni jak i części umożliwiającej odprawienie małych przyjęć plenerowych. Ważną kwestią będzie przyjmowanie gości, którzy będą mogli zjeść smaczny obiad czy też wypić kawę na świeżym powietrzu.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/X++++++PRZESTRZEN+OGOLNODOSTEPNA/X1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/X++++++PRZESTRZEN+OGOLNODOSTEPNA/X2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/X++++++PRZESTRZEN+OGOLNODOSTEPNA/X3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/X++++++PRZESTRZEN+OGOLNODOSTEPNA/X4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/X++++++PRZESTRZEN+OGOLNODOSTEPNA/X5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/X++++++PRZESTRZEN+OGOLNODOSTEPNA/X6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/X++++++PRZESTRZEN+OGOLNODOSTEPNA/X7.jpg']
        },
        {
            link: "ciechocin",
            desc: "OGRÓD W LEŚNYM OTOCZENIU",
            location: "Ciechocin / Kujawsko - Pomorskie",
            area: 1000,
            assum: "\nOgród przydomowy znajdujący się w centrum młodego lasu. Ciche, spokojne miejsce" +
                " z dala od zgiełku miasta w otoczeniu przyrody. Ideą projektu było stworzenie " +
                "nowoczesnego miejsca o funkcji wypoczynkowo – rekreacyjnej. Powstała altana w " +
                "modernistycznym stylu, która reprezentuje trend architektury nowoczesnej. Duże " +
                "tafle szkła w formie drzwi przesuwnych, przez które wnętrze staje się harmonijne " +
                "z otaczającym ogrodem. Biokominek nadaje przytulnej oraz ciepłej atmosfery wnętrzu." +
                " Wewnątrz nie mogło zabraknąć barku, wyspy oraz stołu wykonanego z naturalnego " +
                "drewna, gdzie będzie można miło spędzać czas z przyjaciółmi. Dopełnieniem " +
                "konstrukcji altany jest basen, to on przyniesie ukojenie w gorące letnie dni. ",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/O+OGR%C3%93D+W+LE%C5%9ANYM+OTOCZENIU/O1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/O+OGR%C3%93D+W+LE%C5%9ANYM+OTOCZENIU/O2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/O+OGR%C3%93D+W+LE%C5%9ANYM+OTOCZENIU/O3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/O+OGR%C3%93D+W+LE%C5%9ANYM+OTOCZENIU/O4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/O+OGR%C3%93D+W+LE%C5%9ANYM+OTOCZENIU/O5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/O+OGR%C3%93D+W+LE%C5%9ANYM+OTOCZENIU/O6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/O+OGR%C3%93D+W+LE%C5%9ANYM+OTOCZENIU/O7.jpg']
        },
        {
            link: "zbojno",
            desc: "OGRÓD NA SKARPIE ",
            location: "Zbójno / Kujawsko - Pomorskie",
            area: 3500,
            assum: "\nOgród z nabrzeżem jeziora oraz malowniczym widokiem.  Głównym „problemem” założenia" +
                " projektowego jest SKARPA. Jednak to, co wydaje się wadą może okazać się zaletą i tak " +
                "też było w tym przypadku. Projekt powstał na kilku poziomach terenu. Poziom 0 to " +
                "miejsce reprezentacyjne. Znajduje się na nim budynek mieszkalny, garaż z wyznaczonym" +
                " miejscem dla psów czy też wiata śmietnikowa. Na omawianym poziomie znalazło się " +
                "miejsce dla przydomowego odkrytego jaccuzi. Koncepcja powstała w oparciu o " +
                "geometryczne kształty oraz naturalne materiały. Budynek zyskał ponadczasową " +
                "elewację współgrającą z istniejącą kubaturą. Duże okna oraz ogród zimowy spajają" +
                " przyrodę z wnętrzem domu. Wypoziomowany trawnik oraz miejsce na ognisko to " +
                "kolejny poziom założenia. Duże betonowe, kwadratowe płyty w otoczeniu zieleni " +
                "prowadzą do wgłębnika z siedziskami z oparciem, gdzie w centralnym punkcie znajduje" +
                " się palenisko z cortenu. Obniżenie terenu oraz osłonięcie przez zieleń wysoką " +
                "świetnie zabezpiecza miejsce przed wiatrem. Idąc niżej ścieżką kamienną napotkamy" +
                " drzewa owocowe oraz część gospodarczą. Na samym dole skarpy powstała altana w " +
                "której sercem jest grill z funkcją kominka, piec chlebowy oraz wędzarnia. Pojemne" +
                " szafki, duży blat roboczy oraz półki powodują, że wnętrze jest w pełni funkcjonalne. " +
                "Barek z hokerami oraz stół pomieści wielu przyjaciół. Kompozycja roślinna dobrana" +
                " została w kolorze bieli, różu oraz fioletu. Duża ilość miskantów, rozplenic," +
                " ostnic nadała lekkości kompozycji. Dopełnieniem kompozycji są szałwie, lawendy, " +
                "hortensje bukietowe oraz duża ilość krzewów i drzew.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/P+OGR%C3%93D+NA+SKARPIE/P9.jpg']
        },
        {
            link: "torun",
            desc: "OGRÓD GEOMETRYCZNY NOWOCZESNY",
            location: "Toruń / Kujawsko – Pomorskie ",
            area: 1000,
            assum: "\nOgród przydomowy znajdujący się w centrum miasta, spokojne, ciche osiedle domów " +
                "jednorodzinnych. Ideą projektu było stworzenie geometrycznego, nowoczesnego, przyjaznego " +
                "dla rodziny ogrodu. Przez kształt rabat, powiązanie domu i nawierzchni z kompozycją roślin " +
                "otrzymano czytelną przestrzeń. Przestrzeń została podzielona na strefy takie jak: strefa " +
                "reprezentacyjna, wjazdowa, rekreacyjna, jadalna, użytkowa czy też strefa wypoczynkowa. Główną" +
                " dominantą w ogrodzie, jest wyróżniająca się ściana wodna, która usytuowana została na końcu " +
                "głównej osi kompozycyjnej. Wchodząc głównym wejściem na dany teren, od razu można spostrzec " +
                "ściankę, chociaż jest ona na końcu ogrodu. W strefie reprezentacyjnej, wyróżniają się trzy donice" +
                " wykonane z betonu, które obsadzone zostały drzewami prowadzonymi w formie płaskiego kwadratu" +
                " oraz bylinami. Po lewej stronie strefy znajduje się garaż, przed którym zaprojektowana została " +
                "nawierzchnia ze szlachetnego kamienia w odcieniach szarości oraz bieli.\n\n Wzdłuż osi kompozycyjnej " +
                "zastosowane zostały duże płyty betonowe w kolorach szarych, które nadają nowoczesny charakter " +
                "projektowanego miejsca. Genialnym rozwiązaniem okazał się łącznik pomiędzy budynkami mieszkalnymi, " +
                "który obudowany został taflami szkła. Przez zamykane przesuwne szklane drzwi, łącznik daje możliwość " +
                "przemieszczania się pomiędzy budynkami w mroźne, wietrzne dni. Idąc dalej napotkamy taras, wykonany z " +
                "drewna egzotycznego w ciepłych odcieniach brązu. Zadaszenie wykonane z profili aluminiowych, kryte odporną " +
                "na czynniki zewnętrzne tkaniną, zabezpiecza użytkownika przed ostrym słońcem czy tez deszczem. Na tarasie " +
                "znajduje się również betonowa donica w kształcie litery L w ciemnym odcieniu koloru szarego. Z tyłu pomiędzy " +
                "budynkami znalazło się miejsce na rabatę wielogatunkową, która cechuje się roślinnością kwitnącą przez cały " +
                "okres wegetacji. Dalej znajduje się drugi taras, na którym można odpocząć leżąc na wygodnych leżakach. Na " +
                "końcu działki znajduje się drewniana altana, w której zawisł kokon gdzie można odpocząć. Przy altanie stworzone " +
                "zostało miejsce na ognisko z paleniskiem z cortenu, oraz trzy ozdobne postumenty wykonane z szarego kamienia. " +
                "Aby było funkcjonalnie, w projekcie nie mogło zabraknąć wyniesionego warzywnika oraz domku narzędziowego z drewutnią.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/A+++OGR%C3%93D+GEOMETRYCZNY+NOWOCZESNY/A8.jpg']
        },
        {
            link: "drweca",
            desc: "NATURALISTYCZNY TEREN ZIELENI ",
            location: "Golub - Dobrzyń / Kujawsko – Pomorskie ",
            area: 14000,
            assum: "\nGłównym założeniem mającym na celu zagospodarowanie terenu w miejscowości Golub-Dobrzyń było wykreowanie " +
                "nowoczesnego, przyjaznego miejsca. Podstawowym celem, to podniesienie wartości estetycznej miasta. Kluczowym zaś" +
                " elementem idei projektowej był kształt oraz barwa. Układ kompozycyjny ma charakter geometryczny. Zagospodarowanie" +
                " terenu nad rzeką Drwęcą nie może zakłócić jej naturalnego brzegu. Poprowadzona główna ścieżka spacerowa, a także" +
                " rowerowa posiada cechy dynamiczne, ze względu na swój kształt, a także różnice szerokości. Zaprojektowana została" +
                " kawiarnio-lodziarnia z przeszklonymi ścianami przez co będzie można podziwiać naturalne piękno rzeki. Przy kawiarni" +
                " zostało wydzielone miejsce na taras oraz okrągłe siedzisko, które będzie idealną alternatywą do spotkań grupowych." +
                " Nowoczesny charakter dodaje zielona ściana która obsadzona została ozdobnymi trawami. idąc dalej napotykamy huśtawki" +
                " dla dzieci oraz siłownię plenerową. W środkowej części projektowanego terenu znajduje się miejsce wypoczynkowe na" +
                " planie okręgu, w którym znajdują się dwie modernistyczne kwadratowe altany, wygodne siedziska otoczone gęstą zielenią" +
                " oraz taras widokowy, z którego krajobraz Drwęcy oraz widok na zamek zapiera dech w piersiach. Zaprojektowana została" +
                " również przystań kajakowa, której miastu brakuje do dziś.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/B++++NATURALISTYCZNY+TEREN+ZIELENI/B8.jpg']
        },
        {
            link: "lazienki",
            desc: "PROJEKT ŁAZIENKI",
            location: "Warszawa / Mazowieckie",
            area: 15,
            assum: "\nPiękna i przytulna łazienka stanowi szczególny akcent w każdym domu, czy mieszkaniu. To nie tylko miejsce dbania " +
                "o higienę, ale też źródło odprężenia. Unikalny design uzyskany został przez dobranie ciepłych kolorów oraz faktur" +
                " nadających nietuzinkowy charakter. Blat oraz szafki w odcieniu bieli oraz drewna koloru teak, z wysokim połyskiem." +
                " Projektowana zabudowa na wymiar dodaje nowoczesnego charakteru pomieszczenia. Na blacie zastosowana została ceramiczna" +
                " misa nablatowa wraz z baterią w odcieniu złota. Prysznic z funkcją deszczownicy wraz z odpływem liniowym w połączeniu" +
                " z taflami szkła dodaje wnętrzu ciekawy charakter. Ściany w prysznicu wyłożone gresem drewnopodobnym wydzielają omawiane" +
                " miejsce. Oświetlenie ledowe schowane wokół obniżanego sufitu, widoczne ciemne kwadratowe oprawki, wiszące podłużne" +
                " lampy, wszystko to, w połączeniu z ciepłą barwą światła tworzy harmonijną całość. Dodatki takie jak obrzeża oprawek" +
                " oświetleniowych, lamp wiszących, baterie czy też listwy są w kolorze złota.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/D+++PROJEKT+%C5%81AZIENKI/D1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/D+++PROJEKT+%C5%81AZIENKI/D2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/D+++PROJEKT+%C5%81AZIENKI/D3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/D+++PROJEKT+%C5%81AZIENKI/D4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/D+++PROJEKT+%C5%81AZIENKI/D5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/D+++PROJEKT+%C5%81AZIENKI/D6.jpg']
        },
        {
            link: "pokoj",
            desc: "PROJEKT POKOJU NA PODDASZU ",
            location: "Golub - Dobrzyń / Kujawsko - Pomorskie",
            area: 25,
            assum: "\nIdeą projektu było stworzenie miejsca przytulnego oraz nowoczesnego. Pokój znajduje się na poddaszu, który jest" +
                " bardzo dobrze doświetlony, przez okno połaciowe oraz drzwi balkonowe. Głównym aspektem projektu było uzyskanie" +
                " miejsca do przechowywania oraz miejsce do pracy. W tym celu zaprojektowana została duża szafa, która pomieści " +
                "odzież czy też wiele dokumentów. Fronty szafy to biel z wysokim połyskiem, w połączeniu z wstawkami ciepłych " +
                "odcieniach brązu. Duży blat roboczy pomieści potrzebne elementy tj. monitor, drukarkę, przybory. Ściana na której" +
                " znajduje się tv wykonana jest z betonu architektonicznego, na której przy suficie znalazło się miejsce na " +
                "funkcjonalną szafkę. Całość założenia to odcienie jasnej i ciemnej szarości w połączeniu z bielą oraz ciepłem brązu drewna.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/E++++PROJEKT+POKOJU+NA+PODDASZU/E1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/E++++PROJEKT+POKOJU+NA+PODDASZU/E2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/E++++PROJEKT+POKOJU+NA+PODDASZU/E3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/E++++PROJEKT+POKOJU+NA+PODDASZU/E4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/E++++PROJEKT+POKOJU+NA+PODDASZU/E5.jpg']
        },
        {
            link: "podzamek",
            desc: "OGRÓD W MIEŚCIE",
            location: "Podzamek Golubski / Kujawsko – Pomorskie",
            area: 500,
            assum: "\nOgród przydomowy, geometryczna forma nadaje harmonijny układ kompozycyjny, przez co wnętrze ogrodu" +
                " staje się uporządkowane oraz przejrzyste. Ogród powstał w nawiązaniu do oczekiwań mieszkańców. Główną" +
                " funkcją jest estetyka, która dominuje nad rekreacją. Dominantą w projektowanym ogrodzie jest duża" +
                " surmia bignoniowa, która formowana jest w zwarte drzewo. Kompozycja roślinna to formowane krzewy," +
                " trawy ozdobne oraz byliny. Rabaty zostały bardzo gęsto wypełnione roślinnością, tworzą zwartą " +
                "barwną przestrzeń. Zastosowana została duża ilość traw tj. rozplenice, miskanty czy też turzyce," +
                " które nadają szczególnej lekkości. Hortensje bukietowe, szałwie, lawendy są dopełnieniem ciekawej" +
                " struktury roślinnej.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/I+++++OGR%C3%93D+W+MIE%C5%9ACIE/I1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/I+++++OGR%C3%93D+W+MIE%C5%9ACIE/I2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/I+++++OGR%C3%93D+W+MIE%C5%9ACIE/I3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/I+++++OGR%C3%93D+W+MIE%C5%9ACIE/I4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/I+++++OGR%C3%93D+W+MIE%C5%9ACIE/I5.jpg']
        },
        {
            link: "warszawa",
            desc: "TARAS NA DACHU BUDYNKU",
            location: "Warszawa / Mazowieckie ",
            area: 60,
            assum: "\nTaras mieści się na jednym z Warszawskich apartamentowców. Wymagania inwestora były jasne, stylistyka safari, ma być wygodnie, pielęgnacja ograniczona do minimum oraz przesłona dzięki której osłonięty zostanie taras od wiatru i okien okolicznego budynku. Zaprojektowano taras z kompozytu, który przez swoje właściwości świetnie nadaje się na tego typu inwestycje. W centrum projektowanego tarasu znalazła się donica wypełniona sukulentami oraz opuncjami. W donicach znalazły się również trzy surmie bignoniowe, które mają zadanie osłaniać przed wiatrem, pełnić funkcję estetyczną oraz stanowić osłonę budynku naprzeciw. Funkcję przesłony pełni również technorattan w kolorze szarym. Zaprojektowane zostały również dwie rabaty wypełnione roślinami tj. miskanty, rozchodniki oraz tawułki. Zwieńczeniem tarasu jest pergola, która posiada roletę przeciw niekorzystnymi warunkami atmosferycznymi. Na tarasie znajduje się również postument z cortenu oraz rzeźba.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/J+++++TARAS+NA+DACHU+BUDYNKU/J8.jpg']
        },
        {
            link: "jarczechowo",
            desc: "OGRÓD NA SKARPIE ",
            location: "Kikół / Kujawsko – Pomorskie",
            area: 100,
            assum: "\nPrzepiękna malownicza okolica z widokiem oraz dostępem do jeziora. Projekt zakładał " +
                "stworzenie miejsca w którym inwestor będzie czuł się komfortowo w otoczeniu roślin które " +
                "przez cały sezon wegetacyjny będą dekoracyjne. Budynek z bali, swoim designem wyróżnia się " +
                "ciepłym kolorem brązu czy też szklanym otwarciem widokowym ukierunkowanym na jezioro. Bardzo " +
                "ciekawy charakter obiektu nadał styl projektowanemu miejscu. Rabata na skarpie, która powstała" +
                " cechuje się wielogatunkowością. Przez połączenie roślin z naturalnym kamieniem polnym oraz " +
                "drewnem otrzymaliśmy ciekawą formę skalniaka. Kompozycja roślinna to miskanty, rozplenice," +
                " szałwie, pachnące budleje, rozchodniki, cisy i wiele innych.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/L+++++OGR%C3%93D+NA+SKARPIE/L1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/L+++++OGR%C3%93D+NA+SKARPIE/L2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/L+++++OGR%C3%93D+NA+SKARPIE/L3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/L+++++OGR%C3%93D+NA+SKARPIE/L4.jpg']
        },
        {
            link: "warszawa6",
            desc: "OGRÓD MINIMALISTYCZNY",
            location: "Warszawa / Mazowieckie",
            area: 3000,
            assum: "\nZałożenie projektowe położone na obrzeżach Warszawy. Priorytetem było wykreowanie miejsca rekreacji " +
                "oraz wypoczynku. Powstała nowoczesna altana 35m2 gdzie dwie ściany zostały przeszklone, które mają możliwość" +
                " całkowitego otwarcia szyb przez zamontowany system szyn. W altanie znalazło się miejsce na barek z blatem," +
                " hokerami oraz miejsce na duży stół. Całość oprawiona w klimatyczne odpowiednio dobrane  oświetlenie. " +
                "Zaraz obok altany znajduje się sauna oraz jaccuzi. Ze wszystkich obiektów będzie można korzystać nawet " +
                "zimową porą. Basen jest uwieńczeniem rekreacji w założeniu. Zaprojektowane zostały również ścieżki " +
                "komunikacyjne oraz nawierzchnia przed budynkiem. Zaplanowano również miejsce na dodatkowy garaż wraz " +
                "z wiatą. Roślinność tworzą gatunki nie wymagające nadmiernej pielęgnacji w czasie sezonu wegetacyjnego," +
                " przez co ograniczony został czas nad dodatkowymi pracami ogrodowymi. ",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/M++++OGR%C3%93D+SWOBODNY/M10.jpg']
        },
        {
            link: "bochnia",
            desc: "POD PALMAMI",
            location: "Bochnia / Małopolskie",
            area: 1980,
            assum: "\nGłównym założeniem projektowym była przebudowa oraz unowocześnienie istniejącego już założenia " +
                "ogrodowego. Kompozycja założenia została oparta na prostych kątach, która stworzyła nowoczesny" +
                " charakter przestrzeni. Wydzielono strefę reprezentacyjną, użytkową, wjazdową, rekreacyjną, jadalną," +
                " czy też strefę wypoczynkową. W strefie reprezentacyjnej / wejściowej zaplanowano rabaty wypełnione po " +
                "brzegi roślinnością ozdobną przez cały sezon wegetacyjny. W rabacie pojawiły się również misy z " +
                "cortenu wypełnione wodą. W tylnej części założenia powstał przeszklony ogród zimowy, który będzie " +
                "użytkowany przez cały rok. Taras główny oraz taras pracowniczy wykonany zostanie z trwałego kompozytu " +
                "nowej generacji. Jako soliter w miejscu tym pojawi się również mrozoodporna palma szorstkowiec" +
                " fotunego. Lokalizacja oraz osłonięcie ogrodu pozwala na wykorzystanie palmy w ogrodzie. " +
                "Dopełnieniem kompozycji będzie ściana wodna oraz zielona ściana wykonana z roślin wieloletnich. " +
                "W części rekreacyjnej zaplanowane zostało miejsce na ognisko oraz taras wypoczynkowy otoczone gęstą" +
                " wysoką roślinnością iglastą. W założeniu nie mogło zabraknąć roślin owocowych. Pojawił się sad z " +
                "gruszami, jabłoniami, brzoskwiniami oraz śliwami. Budynek zyskał nową elewację gdzie zastosowano " +
                "kompozyt z wąskimi lamelami w ciepłym odcieniu drewna.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/N++++OGR%C3%93D+NOWOCZESNY/N10.jpg']
        },
        {
            link: "dobrzyn4",
            desc: "NOWOCZESNE WNĘTRZA DOMU",
            location: "Golub-Dobrzyń",
            area: 50,
            assum: "\nMetamorfoza wnętrza domu stworzona została dla małżeństwa z dziećmi. Aranżację wyróżniają naturalne materiały, eleganckie detale oraz różnorodność wzorów i faktur. W projekcie dominują ciepłe odcienie drewna, oraz kontrastująca kolorystyka ścian. Zabudowa kuchenna współgra z kolorystyką przestrzeni salonu. Dzięki tym wszystkim materiałom wnętrze nabrało smaku i stało się niezwykle stylowe.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AB+NOWOCZESNE+WNETRZA+DOMU/AB10.jpg']
        },
        {
            link: "chelmno",
            desc: "OGRÓD REZYDENCJONALNY",
            location: "Chełmno",
            area: 7300,
            assum: "\nZałożenie ogrodowe położone na obrzeżach miasta Chełmno z widokiem na okoliczny las. Fundamentem projektowym było wykreowanie nowoczesnej koncepcji ogrodu przydomowego z uwzględnieniem wytycznych inwestora. Nowoczesna bryła budynków z dużą ilością przeszkleń oraz łącznikiem wyznaczyła otwarcia widokowe poszczególnych części założenia. W części frontowej posesji pojawiły się donice o wielkości 8x8m gdzie wypełnione zostały barwnymi bylinami oraz krzewami. Na osi widokowej  głównego wejścia pojawiły się trzy fontanny wykonane ze stali corten. Tył posesji to część rekreacyjna oraz biesiadna. Zaprojektowany został basen wraz z kaskadą w obiegu zamkniętym, gdzie wylewka tworzyć będzie imponującą ścianę wody. W ogrodzie nie brakuje naturalistycznych rabat. W planach jest zagospodarowanie kilku części ogrodu pod wysiew miododajnych mieszanek łąk kwietnych.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC10.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC11.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC12.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AC+OGROD+REZYDENCJONALNY/AC13.jpg']
        },
        {
            link: "jablonowo",
            desc: "OGRÓD REKREACJI",
            location: "Jabłonowo Pomorskie",
            area: 1500,
            assum: "\nProjekt ogrodu przydomowego położony w Jabłonowie Pomorskim na jednym z osiedli domów jednorodzinnych. Inwestorzy posesji wyznaczyli kilka wytycznych przyszłego projektu, gdzie powstać miał basen, balia, oraz zadaszenie tarasu. Powstała spójna geometryczna koncepcja pod względem wykorzystanych materiałów w całym ogrodzie oraz roślin. W części wypoczynkowej powstała wspomniana balia ogrodowa z piecem zewnętrznym na drewno, duży basen kanadyjski oraz miejsce na hamak zawieszony na ozdobnych stalowych belkach. Na tyłach posesji nie zabrakło również miejsca na huśtawkę, palenisko, wyniesionego warzywnika w skrzyniach oraz miejsca na krzewy owocowe. Zaprojektowana została elewacja wraz z nawierzchnią całej posesji.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AE+OGROD+REKREACJI/AE10.jpg']
        },
        {
            link: "poznan2",
            desc: "OGRÓD WIELOPOZIOMOWY",
            location: "POZNAŃ",
            area: 2500,
            assum: "\nProjekt ogrodu przydomowego położony na obrzeżach Poznania. Wytyczne inwestorów były jasne: „chcemy altanę, ogród zimowy, piwniczkę na wino, oraz miejsce na auta”. Głównym problemem inwestycji była różnica poziomów między końcem a początkiem posesji, gdzie to co „złe” pracownia zamieniła w atut. Pojawiło się tarasowanie skarpy aby otrzymać równe płaskie powierzchnie pomiędzy 3 poziomami. Powstały wygodne schody oraz delikatne skarpy z trawnika, które łączą poszczególne poziomy przez co pielęgnacja murawy nie zostanie utrudniona. Powstał całoroczny przeszklony ogród zimowy, szklarnia, warzywnik, miejsce na drzewa owocowe oraz piwniczka na przetwory oraz własnego wyrobu wina. Najwyższa część ogrodu to miejsce dla nowoczesnej altany, paleniska w otoczeniu lasu oraz szmeru kaskady.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AF+OGROD+WIELOPOZIOMOWY/AF9.jpg']
        },
        {
            link: "dabcze",
            desc: "OGRÓD Z BASENEM",
            location: "DĄBCZE ",
            area: 920,
            assum: "\nProjekt ogrodu przydomowego położony na osiedlu domów jednorodzinnych. Głównym założeniem było zaprojektowanie miejsca rekreacyjnego dla rodziny. W części frontowej powstało reprezentacyjne miejsce eksponujące piękno nowoczesnej  bryły budynku domu jednorodzinnego. Część tylna posesji to strefy przeznaczone  głównie rekreacji oraz wypoczynku. Pojawił się basen, przy którym wzniesione zostały podesty o różnych wysokościach, gdzie jeden z nich będzie pełnił funkcję tarasu słonecznego idealnego na umieszczenie leżaków. Ciekawostką jest ścianka ozdobna, w której umieszczone palenisko gazowe wprowadzi nastrojową atmosferę w ciepłe letnie wieczory. Wgłębnik z paleniskiem będzie dopełnieniem komfortu domowników. Gatunki roślin zostały dobrane tak, aby nie wymagały szczególnej pielęgnacji w okresie wegetacyjnym.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG10.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG11.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG12.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AG+OGR%C3%93D+Z+BASENEM/AG13.jpg']
        },
        {
            link: "tarnow",
            desc: "OGRÓD Z PATIO",
            location: "TARNÓW",
            area: 1300,
            assum: "\nProjekt ogrodu przydomowego położony na jednym z osiedli domów jednorodzinnych w Tarnowie. Głównym założeniem była rewitalizacja istniejącego założenia ogrodowego oraz połączenie oddzielonych wysokim żywopłotem dwóch obiektów nową przestrzenią. W miejscu ściany z żywotnika powstało patio oraz przeszklony łącznik pomiędzy domem jednorodzinnym a budynkiem spa. Inwestorzy bez problemu będą mogli pokonać drogę z domu do budynku spa bez konieczności wychodzenia na zewnątrz. W części patio powstała fontanna, ozdobne belki na których umieszczony został hamak/siedzisko, miejsce na siedziska, stół oraz zaplanowanych zostało nasadzenie wielu odmian bylin. Ciekawostką jest wydzielona część ogrodu gdzie powstała koncepcja w stylu japońskim.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH1.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH2.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH3.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH4.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH5.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH6.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH7.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH8.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH9.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH10.jpg',
                'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AH+OGROD+Z+PATIO/AH11.jpg']
        },
        {
            link: "warszawa7",
            desc: "OGRÓD WIELKOMIEJSKI ",
            location: "Warszawa – Walendów ",
            area: 1500,
            assum: "\nGłównym założeniem projektowym było zaaranżowanie przestrzeni rekreacyjnej oraz biesiadnej dla inwestorów. Koncepcja projektu ogrodu powstała na asymetrycznej działce znajdującej się na osiedlu pod Warszawą. W części rekreacyjnej ogrodu znalazł się basen, wgłębnik wyposażony w palenisko oraz miejsce na wygodne siedziska. Altana będąca częścią projektu będzie stanowiła nie tylko miejsce wypoczynku, lecz jej funkcją będzie wspólne biesiadowanie w gronie znajomych i przyjaciół. Z tego powodu została wyposażona w grill z funkcją kominka, barek, duży rozkładany stół, pojemne szafki, blat roboczy oraz zlew. Dzięki odpowiedniemu rozmieszczeniu rabat oraz drzew uzyskaliśmy efekt otwartej, dobrze zorganizowanej przestrzeni. Zaprojektowana roślinność została dobrana tak, aby nie wymagała szczególnej pielęgnacji.",
            posters: ['https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI1.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI2.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI3.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI4.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI5.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI6.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI7.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI8.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI9.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI10.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI11.jpg',
            'https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/portfolio2/AI++OGROD+WIELKOMIEJSKI/AI12.jpg']
        }];

    num = 0;

    constructor(props, context) {
        super(props, context);
        this.portfolioPoster = React.createRef();
        this.galleryPosters = [];
    }

    render() {
        const elemId = this.projectsContent.findIndex((ele) => ele.link === this.props.id);

        const gallery = this.projectsContent[elemId].posters.map(poster => (
            <div key={poster} className='projectPage__content-portfolio-gallery-poster'>
                <img src={poster} alt="" id={this.num++}
                     ref={gallery => this.galleryPosters.push(gallery)}
                     className='projectPage__content-portfolio-gallery-poster' style={{margin: 0}}
                     onClick={e => {
                         this.portfolioPoster.current.src = poster;
                         this.portfolioPoster.current.classList.add('projectPage__content-portfolio-poster-animation');
                         setTimeout(() => this.portfolioPoster.current.classList.remove('projectPage__content-portfolio-poster-animation'), 1000);
                         this.galleryPosters.find(e => e.classList.remove('projectPage__content-portfolio-gallery-poster-active'));
                         this.galleryPosters[e.target.id].classList.add('projectPage__content-portfolio-gallery-poster-active')
                     }}/>
            </div>
        ));

        return (
            <>
                <h1 className='projectPage__content-title'>{this.projectsContent[elemId].desc}</h1>
                <p className='projectPage__content-location'>
                    <b>Lokalizacja:</b> {this.projectsContent[elemId].location}</p>
                <p className='projectPage__content-location projectPage__content-area'>
                    <b>Powierzchnia:</b> {this.projectsContent[elemId].area} m2</p>
                <p className='projectPage__content-location projectPage__content-assum'>
                    <b>Opis: </b> {this.projectsContent[elemId].assum}</p>
                <div className='projectPage__content-portfolio'>
                    <div className='projectPage__content-portfolio-gallery'>
                        <div id='scrollbar' className='projectPage__content-portfolio-gallery-box'>
                            {gallery}
                        </div>
                    </div>
                    <img src={this.projectsContent[elemId].posters[0]} alt=""
                         className='projectPage__content-portfolio-poster'
                         ref={this.portfolioPoster}/>
                </div>
            </>
        )
    };
}

export default ProjectPageContent;