import React from "react";

const ContactCard = () => {
    return (
        <div className='contactCard'>
            <div className='contactCard__box'>
                <p className='contactCard__box-desc'>PRACOWNIA PROJEKTOWA PIÓRKOWSKI NIE MA WYZNACZONYCH GRANIC.
                    SIEDZIBA FIRMY ZNAJDUJE SIĘ W WOJEWÓDZTWIE KUJAWSKO-POMORSKIM, JEDNAK
                </p>
                <p className='contactCard__box-desc contactCard__box-desc-bol'>
                    DZIAŁAM NA TERENIE CAŁEGO KRAJU.
                </p>
            </div>
        </div>
    )
};

export default ContactCard;