import React from "react";
import SubPageHeader from "../components/SubPageHeader";
import AboutUsWhyUs from "../components/AboutUsWhyUs";
import "../styles/AboutUs.css"

class AboutUs extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.employeeRef = React.createRef()
    }

    handleShowEmployerDescription = () => {
        const employeeRef = this.employeeRef.current;
        if (window.innerHeight / 1.2 > employeeRef.getBoundingClientRect().top) {
            employeeRef.classList.add('aboutUsPage__employees-description-show')
        } else {
            employeeRef.classList.remove('aboutUsPage__employees-description-show')
        }
    };

    componentDidMount() {
        this.handleShowEmployerDescription();
        window.addEventListener('scroll', this.handleShowEmployerDescription)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleShowEmployerDescription)
    }

    render() {
        return (
            <>
                <div className='aboutUsPage'>
                    <div className="aboutUsPage__header">
                        <section className='aboutUsPage__company'>
                            <div className='aboutUsPage__company-description'>
                                <h1 className='aboutUsPage__employees-description-heading'>O firmie</h1>
                                <p className='aboutUsPage__employees-description-normal'>Pracownia
                                    Projektowa Piórkowski zajmuje się architekturą krajobrazu oraz
                                    projektowaniem wnętrz. W ofercie znajduje się również realizacja
                                    oraz pielęgnacja zaprojektowanych ogrodów, kreowanie zieleni we
                                    wnętrzach, nadzorowanie nad pracami wykonawczymi ogrodów
                                    przydomowych oraz projektowanie zdalne zarówno ogrodów jak i
                                    wnętrz. Siedziba firmy znajduje się w woj. Kujawsko-Pomorskim,
                                    jednak jeżeli mieszkasz w innym województwie i zdecydujesz się
                                    na moją ofertę, istnieje możliwość dojazdu na terenie całego
                                    kraju. Celem pracowni jest wykonywanie wyjątkowych oraz
                                    prestiżowych projektów, spełniających oczekiwania nawet
                                    najbardziej wymagających klientów. Firma zapewnia kompleksową
                                    obsługę na każdym etapie współpracy, stawia na uczciwość,
                                    rzetelność, oryginalność, naturalne materiały oraz zaskakujące
                                    detale.</p>
                            </div>
                            <div className='aboutUsPage__employees-description-logo'/>
                        </section>
                    </div>
                    <SubPageHeader center={'kto projektuje?'}
                                   bottom={'sprawdź z kim masz do czynienia'}/>
                    <div className='aboutUsPage__employees'>
                        <img
                            src="https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/%22o+mnie%22/z1.jpg"
                            alt="" className='aboutUsPage__employees-photo'/>
                        <div className='aboutUsPage__employees-description' ref={this.employeeRef}>
                            <h1 className='aboutUsPage__employees-description-heading'>PATRYK
                                PIÓRKOWSKI</h1>
                            <h2 className='aboutUsPage__employees-description-position'>mgr inż.
                                architekt krajobrazu</h2>
                            <h2 className='aboutUsPage__employees-description-position'>właściciel
                                firmy oraz architekt pracowni projektowej</h2>
                            <p className='aboutUsPage__employees-description-normal'>Od kiedy
                                pamiętam interesowałem się naturą, stworzonymi przez przyrodę
                                fakturami oraz kształtami. Przygodę z projektowaniem rozpocząłem w
                                2009r. w Technikum Architektury Krajobrazu w Toruniu. Uzyskałem tam
                                uprawnienia, które otworzyły mi możliwości spełniania się w tym, co
                                kocham. Kontynuowałem edukację na Uniwersytecie Warmińsko-Mazurskim
                                w Olsztynie, kończąc studnia z tytułem magistra inżyniera architekta
                                krajobrazu. W dalszym ciągu systematycznie podnoszę swoje
                                kompetencje, uczestnicząc w szkoleniach oraz targach. Ponadto, już w
                                czasie studiów zacząłem interesować się projektowaniem wnętrz, co
                                otworzyło mi nowe możliwości w rozwoju zawodowym. Doświadczenie
                                zdobywałem w pracowniach projektowych i wykonawczych. Na bieżąco
                                śledzę nowe technologie, dzięki którym uzyskuję możliwość poznawania
                                nowych trendów w projektowaniu. Dążę do tworzenia projektów
                                perfekcyjnych, o przemyślanej funkcjonalności, stylu i formie. </p>
                        </div>
                    </div>
                    <SubPageHeader center={'dlaczego piórkowski?'}
                                   bottom={'skorzystaj z usług profesjonalisty'}/>
                    <AboutUsWhyUs/>
                </div>
            </>
        )
    };
}

export default AboutUs