import React from 'react';
import SubPageHeader from "./SubPageHeader";


class OfferInteriorsContentOfOffer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.imgRef = React.createRef();
    }

    handleStick = () => {
        const imgRef = this.imgRef.current;
        if (window.scrollY >= window.innerWidth * 0.13) {
            imgRef.classList.add('offerContent__aside-stick')
        } else {
            imgRef.classList.remove('offerContent__aside-stick')
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleStick)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleStick)
    }

    render() {
        if (this.props.id === 'projekty') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'projektowanie wnętrz'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>projekty</h1>
                        <p className='offerContent__text-normal'>Każdy z domowników ma swoją własną wizję wymarzonego
                            wnętrza i znalezienie kompromisu podczas projektowania stanowi często nie małe wyzwanie.
                            Umiejętne wykorzystanie przestrzeni poprawia jego funkcjonalność. Zatrudniając projektanta
                            wybiegasz w przyszłość, minimalizujesz koszty oraz redukujesz do zera zmiany, które powstają
                            podczas wykonywania wnętrza bez projektu. Nowoczesna technologia pozwala idealnie odwzorować
                            to, jak wnętrze będzie wyglądało w przyszłości. Dzięki fotorealistycznym obrazom oraz
                            animacjom możesz poczuć się jak byś oglądał pomieszczenia w których będziesz już niedługo
                            przebywał. Co ważne projekt wykonywany jest kompleksowo i zawiera całą niezbędną
                            dokumentację techniczną, wykluczając tym samym pomyłki podczas wykonania. Do dokumentacji
                            dołączony jest również kosztorys zawierający elementy wnętrzarskie wraz z materiałami
                            występującymi na rynku. Oszczędzasz więc swój czas, który musiałbyś poświęcić szukając
                            produktów na własną rękę. Z chęcią zdejmę z Ciebie ten ciężar i przedstawię Ci idealne
                            rozwiązania do Twojego wnętrza.</p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W1.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'uklad-funkcjonalny') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'Układ funkcjonalny'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Układ funkcjonalny</h1>
                        <p className='offerContent__text-normal'>Układ funkcjonalny jest to obraz przedstawiający rzut
                            mieszkania z góry, z wyrysowanymi ścianami, meblami i podstawowymi elementami wyposażenia
                            wnętrza. Jest on o tyle ważny, że tworzony jest na podstawie sytuacji życiowej oraz
                            przyzwyczajeń inwestora. Pozwala on określić jakiej wielkości meble będą pełniły w danym
                            pomieszczeniu swoją funkcję oraz określa układ dostosowany tylko i wyłącznie do danego
                            projektu. To często dzięki niemu oszczędzamy wiele pieniędzy oraz nerwów unikając wyburzania
                            ścian i stosowania mało praktycznych rozwiązań. Dodatkową korzyścią wynikającą z układu
                            funkcjonalnego jest to, że to na jego podstawie wykonuje się projekt elektryki, hydrauliki i
                            kanalizacji i już na tym etapie można określić idealne miejsca na rozmieszczenie gniazdek,
                            punktów świetlnych oraz odpływów. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W2.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'oswietlenie') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'Oświetlenie'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Oświetlenie</h1>
                        <p className='offerContent__text-normal'>Minęły już czasy, w których w pomieszczeniach była
                            zamontowana tylko jedna lampa na środku sufitu plus dodatkowo ewentualnie kinkiety na
                            ścianach. Światło odgrywa we wnętrzu bardzo ważną rolę. Idealnie zaplanowany projekt
                            oświetlenia uwzględnia jego trzy rodzaje : światło główne, dekoracyjne oraz robocze. Dzięki
                            nim możliwe jest podkreślenie elementów, które chcemy uwypuklić, stworzenie nastroju oraz
                            wykorzystanie światła tak, by spełniało swoją rolę np. przy lustrze, nad blatami w kuchni
                            czy przy biurku. Projekt wnętrza który otrzymasz będzie zawierał w sobie nie tylko
                            rozmieszczenie punktów świetlnych, lecz również skompletowane produkty oświetleniowe wraz z
                            ich wyceną.</p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W3.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'podlogi') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'Podłogi'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Podłogi</h1>
                        <p className='offerContent__text-normal'>Podłogi zajmują oprócz sufitów największą powierzchnię
                            mieszkań. Układ podłóg będzie oddziaływał na cały projekt mieszkania, a aranżacja wnętrz bez
                            podłóg jest niemożliwa. Za pomocą odpowiednio dobranej podłogi może zmienić się cała
                            aranżacja wnętrza. Ułożenie podłogi może optycznie skrócić i poszerzyć pomieszczenie, lub
                            poszerzyć pomieszczenie nie zmieniając jego proporcji. Linia łącząca dwa różne materiały
                            podłogowe powinna być starannie wytyczona zgodnie z granicami architektonicznymi tak, by
                            uniknąć wrażenia bałaganu w pomieszczeniu. Pomogę Ci dostosować najlepsze rozwiązania dla
                            Twoich pomieszczeń i sprawię, że będzie to przemyślana inwestycja. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W4.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'sufity-podwieszane') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'Sufity podwieszane'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Sufity podwieszane</h1>
                        <p className='offerContent__text-normal'>Sufit podwieszany może mieć wiele zastosowań. Tuszuje
                            nierówności ścian, chowa instalacje elektryczne i wentylacyjne oraz wyrównuje zróżnicowany
                            poziom w pomieszczeniach. Sufity podwieszane najczęściej formujemy na planie kwadratu czy
                            prostokąta, by na krawędziach zainstalować punkty świetlne. Możemy zastosować również taśmy
                            LED, które podkreślą poziomy sufitu podwieszanego, zarysują na suficie kształt mebli, ściany
                            z telewizorem, wanny czy brodzika. Sufit podwieszany wpływa na wygląd wnętrza i powinien być
                            zaprojektowany tak, by pasował do wszystkich elementów wystroju. Z chęcią pokażę Ci na
                            projekcie jak takie rozwiązanie mogłoby wyglądać w Twoim pomieszczeniu. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W5.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'meble-na-wymiar') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'Meble na wymiar'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Meble na wymiar</h1>
                        <p className='offerContent__text-normal'>Chcesz żeby Twoje meble były oryginalne oraz dopasowane
                            idealnie do pomieszczenia? Zaprojektuję je specjalnie dla Ciebie. Projektuję kompleksowo
                            wszelkie koncepcje, również te najbardziej nietypowe. Meble kuchenne, łazienkowe, pokojowe,
                            do sypialni dla dzieci czy młodzieży już nie muszą być nudne i takie same jak wszystkie. W
                            projekcie wnętrza dostaniesz pełną dokumentację dotyczącą mebli na wymiar, gotową do
                            przedstawienia firmie wykonawczej. Remont oraz dobór mebli nigdy nie był tak prosty.
                            Projektowanie mebli na wymiar warto zlecić doświadczonemu projektantowi, który sprawi, że
                            meble nie będą zachwycały tylko i wyłącznie designem, ale również korzystanie z nich będzie
                            czystą przyjemnością.</p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W6.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'elementy-dekoracyjne') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'Elementy Dekoracyjne'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Elementy Dekoracyjne</h1>
                        <p className='offerContent__text-normal'>Czym było by pomieszczenie bez dodatków dekoracyjnych…
                            Jest to element wieńczący projektowanie, jednak tak samo istotny jak wszystkie pozostałe.
                            Odpowiednio dobrane dodatki odzwierciedlają duszę oraz styl właściciela. Dobranie ich taki
                            sposób, by współgrały z pomieszczeniami oraz wprowadziły do niego życie najlepiej powierzyć
                            profesjonaliście. Dzięki wizualizacjom oraz animacji pokażę Ci w jaki sposób będzie
                            wyglądało Twoje wnętrze. Zatroszczę się o każdy detal w taki sposób, by Twoje pomieszczenie
                            nabrało zupełnie nowego charakteru. Boisz się, że nie trafię w Twój gust? Wstępne
                            wizualizacje przedstawię Ci już na etapie koncepcji projektowej, więc już w tym momencie
                            będziesz mógł ocenić czy sprostałem Twoim oczekiwaniom. Podczas projektowania dążę do 100 %
                            zadowolenia klienta i jestem otwarty na sugestie dotyczące popraw. </p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W7.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'zielone-wnetrza') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'Zielone wnętrza'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>Zielone wnętrza</h1>
                        <p className='offerContent__text-normal'>Zieleń odpręża, poprawia mikroklimat, a przede
                            wszystkim polepsza jakość powietrza, oczyszczając je z zanieczyszczeń. Aranżuję zieleń w
                            domach, biurach, obiektach użyteczności publicznej. W biurze spędzamy czas średnio 8h
                            dziennie. Czy nie lepiej pracuje się w naturalnie oczyszczonym powietrzu? Pracownia
                            Projektowa Piórkowski zajmuje się zielenią wewnątrz pomieszczeń. Roślinność dopasuję pod
                            względem hodowlanym oraz estetycznym i zaplanuję jej rozmieszczenie uwzględniając Twoje
                            potrzeby. Jest to coraz bardziej popularny trend, po który sięgają projektanci na całym
                            świecie.</p>
                    </article>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W8.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        } else if (this.props.id === 'nadzory') {
            return (
                <>
                    <div className='offerContent__header'>
                        {<SubPageHeader center={'nadzory'}
                                        bottom={'zapoznaj się z ofertą'}/>}
                    </div>
                    <div className="offerContent--mobile768">
                    <article className='offerContent__text'>
                        <h1 className='offerContent__text-heading'>nadzory</h1>
                        <p className='offerContent__text-normal'>Aby wykonywane prace były zgodne z projektem, oferuję
                            autorski nadzór nad pracami wykonawczymi wnętrz. Aby osiągnąć najlepszy efekt realizacji,
                            obecność i kontrola na budowie jest bardzo ważna. Pełniąc nadzór pomogę również rozwiązać
                            wszelkie dodatkowe niuanse, jakie zawsze mogą pojawić się w trakcie prowadzenia prac.</p>
                    </article>
                    <br/>
                    <img
                        src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta/WNETRZA/W9.jpg'
                        alt="" className='offerContent__aside' ref={this.imgRef}/>
                    </div>
                </>
            );
        }
    };
}

export default OfferInteriorsContentOfOffer;