import React from "react";
import {NavLink} from "react-router-dom";
import '../styles/Navigation.css'

class Navigation extends React.Component {
    list = [
        {name: 'o firmie', path: '/o-firmie'},
        {name: 'oferta', path: '/oferta'},
        {name: 'portfolio', path: '/portfolio'},
        {name: 'projekty', path: '/projekty'},
        {name: 'kontakt', path: '/kontakt'},
    ];
    menu = this.list.map(item => (
        <li key={item.name} className='navi__list-item'>
            <NavLink className='navi__list-item-link' to={item.path}
                     exact={item.exact ? item.exact : false}>{item.name}</NavLink>
        </li>
    ));

    constructor(props, context) {
        super(props, context);
        this.naviRef = React.createRef();
        this.menuRef = React.createRef();
    }

    mountAfterPageChange = () => {
        const naviNode = this.naviRef.current;
        if (window.location.pathname !== '/') {
            window.scrollTo(0, 0);
            naviNode.classList.add("nav__scrolling");
        } else if (window.location.pathname === '/') {
            window.scrollTo(0, 0);
            naviNode.classList.remove("nav__scrolling");
        }
    };

    onScrolling = () => {
        const naviNode = this.naviRef.current;
        if (window.scrollY > 50) {
            naviNode.classList.add("nav__scrolling");
        } else if (window.location.pathname === '/') {
            naviNode.classList.remove("nav__scrolling");
        }
    };

    componentDidMount() {
        if (window.location.pathname !== '/') {
            this.naviRef.current.classList.add("nav__scrolling");
        }
        window.addEventListener('scroll', this.onScrolling)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrolling)
    }

    render() {
        return (
            <>
                <nav className='navi' ref={this.naviRef} onClick={this.mountAfterPageChange}>
                    <div className='navi__list-logo'>
                        <NavLink className='navi__list-logo-link' to='/'
                                 exact={true}/>
                    </div>
                    <ul className='navi__list'>
                        <div className='navi__list-items-box' ref={this.menuRef}>
                            {this.menu}
                        </div>
                        <div className="navi__list-item-collapse-menu" onClick={() => this.menuRef.current.classList.toggle('navi__list-item-collapse-menu-show')}>
                            <i className='far fa-caret-square-down navi__list-item-collapse-menu-ico' />
                        </div>
                    </ul>
                    <div className="navi__list-media">
                        <a className='navi__list-media-link'
                           href="https://www.facebook.com/Pracownia-Projektowa-Pi%C3%B3rkowski-113250600219568/"
                           rel='noopener noreferrer'
                           target="_blank">
                            <i className="fab fa-facebook-square navi__list-media-ico"/></a>
                        <a className='navi__list-media-link'
                           href="https://www.instagram.com/patrykpiorkowski/?igshid=1d46fif26n5qz&fbclid=IwAR2UMTBQpHCcvZuCK7Yts_bNlcLKqoTdhXqowVIkKZaRWAmtj4_PmkEb6Q4"
                           rel='noopener noreferrer'
                           target="_blank">
                            <i className="fab fa-instagram navi__list-media-ico"/></a>
                        <a className='navi__list-media-link'
                           href="https://www.youtube.com/channel/UCV29KmOWtbgv_LMM_D7m3Qg"
                           rel='noopener noreferrer'
                           target="_blank">
                            <i className="fab fa-youtube navi__list-media-ico"/></a>
                    </div>
                </nav>
            </>
        )
    };
}

export default Navigation