import React from "react";
import {Form, FormGroup, Input, Button} from 'reactstrap';
import {Link} from "react-router-dom";
import axios from 'axios'
import '../styles/ContactPage.css'
import ContactCard from "../components/ContactCard";

class Contact extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            name: '',
            email: '',
            place: '',
            phone: '',
            option: '',
            area: '',
            message: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.name = React.createRef();
        this.email = React.createRef();
        this.place = React.createRef();
        this.phone = React.createRef();
        this.area = React.createRef();
        this.message = React.createRef();
    }

    handleChange = change => {
        this.setState({[change.target.name]: change.target.value});
    };

    async handleSubmit(event) {
        event.preventDefault();
        const {name, email, place, phone, option, area, message} = this.state;
        await axios.post('/contact/form', {
            name,
            email,
            place,
            phone,
            option,
            area,
            message
        }).finally(
            alert('wiadomość wysłana'),
            this.name.current.value = '',
            this.email.current.value = '',
            this.place.current.value = '',
            this.phone.current.value = '',
            this.area.current.value = '',
            this.message.current.value = ''
        )
    }

    render() {
        return (
            <>
                <div className='contactForm__header'>
                    <ContactCard/>
                </div>
                <div className='contactForm'>
                    <Form className='contactForm_box' onSubmit={this.handleSubmit}>
                        <h1 className='contactForm_box-header'>Formularz kontaktowy</h1>
                        <FormGroup>
                            <i className="fas fa-address-book contactForm_box-ico"> </i><Input
                            className='contactForm_box-form' type='text' name='name'
                            placeholder="Imie i nawisko" innerRef={this.name}
                            onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <i className="fas fa-envelope-open-text contactForm_box-ico"> </i><Input
                            className='contactForm_box-form' type='email' name='email' required
                            placeholder="Adres e-mail" innerRef={this.email}
                            onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <i className="fas fa-map-pin contactForm_box-ico"> </i><Input
                            className='contactForm_box-form' type='text' name='place'
                            placeholder="Miejscowość" innerRef={this.place}
                            onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <i className="fas fa-phone-square contactForm_box-ico"> </i><Input
                            className='contactForm_box-form contactForm_box-form-number'
                            type='number'
                            name='phone' placeholder="Numer kontaktowy" innerRef={this.phone}
                            onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <i className="fas fa-check-circle contactForm_box-ico"> </i><select
                            className='contactForm_box-form contactForm_box-form-select' required
                            onChange={this.handleChange} name='option' defaultValue={'default'}>
                            <option value='default' disabled>Temat wiadomości</option>
                            <option value='Ogrody'>Ogrody</option>
                            <option value='Wnętrza'>Wnętrza</option>
                        </select>
                        </FormGroup>
                        <FormGroup>
                            <i className="fas fa-square contactForm_box-ico"> </i><Input
                            className='contactForm_box-form contactForm_box-form-number'
                            type='number'
                            name='area' placeholder="Powierzchnia m2" innerRef={this.area}
                            onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <i className="fas fa-sticky-note contactForm_box-ico"
                               style={{opacity: 0}}> </i><Input id='scroll'
                                                                className='contactForm_box-form contactForm_box-form-message'
                                                                type='textarea' name='message'
                                                                required
                                                                placeholder="Wiadomość"
                                                                innerRef={this.message}
                                                                onChange={this.handleChange}/>
                        </FormGroup>
                        <p className='contactForm-private_policy'>
                            Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o
                            ochronie danych osobowych oraz Rozporządzenia 2016/679. Podanie danych
                            jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem
                            poinformowany, że przysługuje mi prawo dostępu do swoich danych,
                            możliwości ich poprawiania, żądania zaprzestania ich przetwarzania.
                            Administratorem danych osobowych jest Piórkowski Pracownia Projektowa.
                            Więcej informacji dot. ochrony danych osobowych znajdą Państwo w
                            <Link style={{color: 'black', textTransform: 'uppercase',}}
                                  to={'polityka-prywatnosci'}> polityce prywatności</Link>
                            . Klikając WYŚLIJ akceptujesz wszystkie powyższe warunki.
                        </p>
                        <Button
                            className='portfolioPage__more contactForm_box-button'>Wyślij
                        </Button>
                        <div className='contactCard__box-desc'>
                            <i className="fas fa-phone-alt contactCard__box-desc-contact contactCard__box-desc-contact--contact">508 178 733</i>
                            <i className="far fa-envelope-open contactCard__box-desc-contact contactCard__box-desc-contact--contact">pracownia.piorkowski@gmail.com</i>
                        </div>
                    </Form>
                    <p className='private_policy'>
                        Koszt projektu zieleni zależy przede wszystkim od powierzchni projektowanego ogrodu oraz stopnia
                        skomplikowania ukształtowania terenu.<br/><br/>
                        Koszt projektu wnętrza uzależniony jest od metrów kwadratowych projektowanych
                        pomieszczeń.<br/><br/>
                        Na koszty projektu składają się również inwentaryzacja, ilość koncepcji projektowych oraz
                        wizualizacji projektu.<br/><br/>
                        W celu otrzymania szczegółowej oferty proszę o kontakt.<br/><br/>
                        Możesz do mnie napisać, zadzwonić lub skorzystać z formularza kontaktowego. Możliwie najszybciej
                        odpowiem na nurtujące pytania czy wątpliwości. Jestem zawsze do dyspozycji w zakresie sugestii,
                        pomocy, porady czy konsultacji.
                    </p>
                </div>
            </>
        )
    };
}

export default Contact;