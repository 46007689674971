import React from "react";
import '../styles/Project.css'
import ico1 from '../images/icon/i1.png'
import ico2 from '../images/icon/i2.png'
import ico3 from '../images/icon/i3.svg'
import SubPageHeader from "../components/SubPageHeader";
import ProjectPageBox from "../components/ProjectPageBox";

class Project extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.refGarden = React.createRef();
        this.refIco1 = React.createRef();
        this.refIco2 = React.createRef();
        this.mapRef = React.createRef()
    }

    handleShowMap = () => {
        const mapRef = this.mapRef.current;
        if (window.innerHeight / 1.2 > mapRef.getBoundingClientRect().top) {
            mapRef.classList.add('remoteDesignPage__range-map-show')
        } else {
            mapRef.classList.remove('remoteDesignPage__range-map-show')
        }
    };

    componentDidMount() {
        this.handleShowMap();
        window.addEventListener('scroll', this.handleShowMap)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleShowMap)
    }

    render() {
        return (
            <div className='remoteDesignPage'>
                <div className='remoteDesignPage__header'>
                    <SubPageHeader center={'co zawiera projekt'}
                                   bottom={'ty decydujesz co się w nim znajdzie'}/>
                </div>
                <div className='remoteDesignPage_description'>
                    <div className='remoteDesignPage_description-box'>
                        <img
                            className='remoteDesignPage_description-box-ico remoteDesignPage_description-box-ico-active'
                            src={ico2} alt="" ref={this.refIco2}
                            onClick={() => {
                                this.refGarden.current.classList.remove('remoteDesignPage__garden-active');
                                this.refIco1.current.classList.remove('remoteDesignPage_description-box-ico-active');
                                this.refIco2.current.classList.add('remoteDesignPage_description-box-ico-active');
                            }}/>
                        <img className='remoteDesignPage_description-box-ico' src={ico1} alt=""
                             ref={this.refIco1}
                             onClick={() => {
                                 this.refGarden.current.classList.add('remoteDesignPage__garden-active');
                                 this.refIco2.current.classList.remove('remoteDesignPage_description-box-ico-active');
                                 this.refIco1.current.classList.add('remoteDesignPage_description-box-ico-active');
                             }}/>
                    </div>
                    <aside id='scroll' className='remoteDesignPage__interiors'>
                        <h1 className='remoteDesignPage__text-heading'>ZAWARTOŚĆ TWOJEGO PROJEKTU WNĘTRZA</h1>
                        <ul className='remoteDesignPage__text-list'>
                            <li>Układ funkcjonalny</li>
                            <li>Projekt koncepcyjny</li>
                            <li>Projekt instalacji elektrycznej</li>
                            <li>Projekt i dobór oświetlenia</li>
                            <li>Rozwinięcia ścian w widokach</li>
                            <li>Projekt układu podłóg</li>
                            <li>Projekt aranżacji ścian</li>
                            <li>Projekt zabudowy na wymiar</li>
                            <li>Projekt sufitów</li>
                            <li>Realistyczne wizualizacje</li>
                            <li>Animacja / spacer 3D</li>
                            <li>Kosztorys wraz zapotrzebowaniem materiałów</li>
                            <img className='remoteDesignPage__box-ico' src={ico3} alt="box_icon"/>
                        </ul>
                    </aside>
                    <section id='scroll' className='remoteDesignPage__garden' ref={this.refGarden}>
                        <h1 className='remoteDesignPage__text-heading'>ZAWARTOŚĆ TWOJEGO PROJEKTU OGRODU</h1>
                        <ul className='remoteDesignPage__text-list'>
                            <li>Układ funkcjonalny</li>
                            <li>Projekt koncepcyjny</li>
                            <li>Projekt nawierzchni</li>
                            <li>Projekt elewacji</li>
                            <li>Projekt oświetlenia</li>
                            <li>Projekt ogrodzenia</li>
                            <li>Projekt małej architektury</li>
                            <li>Realistyczne wizualizacje</li>
                            <li>Pełen pakiet rysunków technicznych m.in.</li>
                            <ul className='remoteDesignPage__text-sublist'>
                                <li>Projekt techniczny nasadzeń</li>
                                <li>Wymiarowanie terenu</li>
                                <li>Powierzchnie projektowane</li>
                                <li>Projekt instalacji np. elektryka / nawadnianie</li>
                            </ul>
                            <li>Animacja / spacer 3D</li>
                            <li>Katalog roślinności</li>
                            <li>Kosztorys wraz z zapotrzebowaniem materiałów</li>
                            <img className='remoteDesignPage__box-ico' src={ico3} alt="box_icon"/>
                        </ul>
                    </section>
                </div>
                <div>
                    <SubPageHeader center={'JAK BĘDZIE PRZEBIEGAŁA NASZA WSPÓŁPRACA?'}
                                   bottom={'SPRAWDŹ, NA CO SIĘ PISZESZ'}/>
                </div>
                <ProjectPageBox/>
                <div>
                    <SubPageHeader center={'PROJEKTOWANIE ZDALNE'}
                                   bottom={'WYKORZYSTAJ OPCJĘ PROJEKTOWANIA - NA ODLEGŁOŚĆ'}/>
                </div>
                <div className='remoteDesignPage__range-box'>
                    <div className='remoteDesignPage__range-description'>
                        <p className='remoteDesignPage__range-description-normal'>Koncepcja projektu zdalnego powstała w
                            odpowiedzi na oczekiwania klientów, którzy z różnych przyczyn nie mogą uczestniczyć w
                            spotkaniach osobistych. Jeśli jesteś jedną z tych osób, to przygotowałem dla Ciebie ofertę,
                            w
                            której znajduje się kompletny projekt wysłany w finalnej części drogą elektroniczną lub
                            tradycyjną – zależnie od Twoich preferencji. Jedyną różnicą w stosunku do projektów, które
                            wykonuję z moją obecnością u inwestora jest sposób komunikacji, który w tym przypadku jest
                            możliwy za pomocą dostępnych komunikatorów oraz drogą telefoniczną. Współpracę zaczynamy od
                            wypełnienia przez Ciebie mojej autorskiej ankiety, dzięki której łatwiej mi będzie poznać
                            Twoje
                            potrzeby. Chcesz porozmawiać o szczegółach? Napisz lub zadzwoń, a z pewnością rozwieję każdą
                            Twoją wątpliwość. </p>
                    </div>
                    <img className='remoteDesignPage__range-map'
                         src='https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/projekty/MAPA.jpg'
                         ref={this.mapRef} alt="map"/>
                </div>
            </div>
        )
    };
}

export default Project