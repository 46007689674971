import React from "react";
import {Link} from "react-router-dom";
import '../styles/PortfolioBlock.css'

class PortfolioBlock extends React.Component {

    render() {
        const projectPage = this.props.projectList.map(project => (
            <div key={project.link} className='portfolioBlock__img'>
                <img className='portfolioBlock__img-poster' src={project.img} alt=""/>
                <div className='portfolioBlock__img-content'>
                    <p className='portfolioBlock__img-content-desc'>{project.desc}</p>
                    <Link to={`/portfolio/${project.link}`} key={project.link}
                          className='portfolioBlock__img-content-desc portfolioBlock__img-content-link'
                          onClick={() => window.scrollTo(0, 0)}>zobacz</Link>
                </div>
            </div>
        ));

        return (
            <div className='portfolioBlock'>
                {projectPage}
            </div>
        )
    }
}

export default PortfolioBlock